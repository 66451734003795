<template>
  <div class="cnz scrollable">
    <SimpleHero
      height="70vh"
      show-login-and-register
      hide-logo
    />
    <Container class="cnz__info--container">
      <div class="cnz__info">
        <h1 class="mb-05 mt-4rem dm-sans-font" style="margin-bottom: 2rem;" v-html="registerAndExploreTitle"></h1>
        <p v-html="registerAndExploreDescription"></p>
      </div>

      <div style="margin-top: 4rem;">
        <h1 style="margin-bottom: 1rem;">{{ actorNumbersTitle }}</h1>
        <facet-card
          :facet-counts="facetCounts"
          @click="facetCardClick"
        />
      </div>

      <div class="platform-benefits">
        <h1 class="mb-05 mt-4rem dm-sans-font">Platform Benefits</h1>
        <div class="platform-benefits-container">
          <div class="platform-benefit">
            <h2>Find Partners Faster</h2>
            <span v-if="$store.getters.isEcHealth">Accelerate your networking. Our curated ecosystem connects you directly with the right partners, facilities, and resources to accelerate your innovation journey.</span>
            <span v-if="$store.getters.isEcAerospace">Accelerate your networking. Our curated ecosystem connects you directly with the right partners, facilities, and resources to accelerate your innovation journey. </span>
          </div>
          <div class="platform-benefit">
            <h2>Access Expert Knowledge</h2>
            <span
              v-if="$store.getters.isEcHealth">Tap into Canterbury's world-class expertise in health innovation, from regulatory guidance to commercial development support.</span>
            <span v-if="$store.getters.isEcAerospace">Tap into New Zealand's world-class expertise in aerospace innovation, from regulatory guidance to commercial development support.</span>
          </div>
          <div class="platform-benefit">
            <h2>Join a Thriving Community</h2>
            <span v-if="$store.getters.isEcHealth">Become part of New Zealand's fastest-growing health innovation hub, where clinicians, researchers, and entrepreneurs collaborate to create impactful solutions.</span>
            <span v-if="$store.getters.isEcAerospace">Become part of New Zealand's fastest-growing aerospace innovation hub, where entrepreneurs, researchers, and supporters collaborate to create impactful solutions. </span>
          </div>
        </div>
        <div class="platform-benefit big">
          <div style="width: 80%; margin: 0 auto; display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: space-between;">

            <div>
              <h2>Through our platform, you gain access to:</h2>

              <div>
              <span style="margin-bottom: 0px;">
                <ul style="list-style-type: circle; margin-left: 20px;">
                  <li>World-class research and development facilities</li>
                  <li>Commercialisation support and funding connections</li>
                  <li>Industry partnerships and market access</li>
                  <li>Regulatory and quality management guidance</li>
                </ul>
              </span>
              </div>
            </div>

            <DsButton
              variant="rounded"
              label="Register now"
              to="/register"
              style="background-color: #ED1C24; text-align: center; height: 40px;"
            />
          </div>
        </div>

      </div>
    </Container>

    <div class="cnz__upcoming-event">
      <div class="cnz_events">
        <Container>
          <h1 class="mb-05 dm-sans-font" style="margin-top: 2rem;" v-html="whoIsPlatformForTitle"></h1>
          <p v-html="whoIsPlatformForDescription"></p>
        </Container>

        <div class="cnz_who_section" style="max-width: 1300px; margin: 0 auto; margin-top: 2rem; margin-bottom: 2rem;">
          <div class="cnz_who_section--card" v-for="card in cardData">
            <div style="min-height: 190px; max-height: 190px;">
              <img :src="card.image" :alt="card.title">
            </div>
            <h4 style="font-size: 18px; color: black;" class="dm-sans-font">{{ card.title }}</h4>
            <p style="font-size: 16px; color: black; margin-bottom: 2rem;">{{ card.description }}</p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <Container>
        <div>
          <h1 class="mb-05 mt-4rem dm-sans-font">{{ whyUniqueTitle }}</h1>
          <p class="why-unique-text" v-html="whyUniqueText"></p>
        </div>
      </Container>

      <div class="cnz_who_section" style="max-width: 1300px; margin: 0 auto; margin-top: 2rem;">
        <div class="cnz_who_section--card cluster" v-for="card in clusterCardData">
          <img :src="card.image">
          <h4 style="font-size: 18px; margin-top: 2rem;" class="dm-sans-font">{{ card.title }}</h4>
          <p style="font-size: 16px;">{{ card.description }}</p>
        </div>
      </div>
    </div>

    <Container class="cnz-orchestrators">
      <h1 class="mb-05 mt-4rem">{{ whoWeAreTitle }}</h1>
      <div class="image-container" v-if="$store.getters.isEcHealth">
        <img src="/images/static-home-page/ec-health/christchurchNZ.png"/>
        <img src="/images/static-home-page/ec-health/medtech-iq.png"/>
      </div>
      <div class="image-container-ec-aerospace" v-else-if="$store.getters.isEcAerospace">
        <div class="cnz_who_section">
          <div class="cnz_who_section--card cluster">
            <div class="cnz_who_section_image_container">
              <img src="/images/static-home-page/ec-health/christchurchNZ.png"/>
            </div>
            <p style="font-size: 16px;">ChristchurchNZ is the sustainable economic development agency for the city and all of our work is focused on making Ōtautahi Christchurch a
              better place to live, work and play. </p>
          </div>

          <div class="cnz_who_section--card cluster">
            <div class="cnz_who_section_image_container">
              <img src="/images/static-home-page/ec-health/aerospace-nz-logo.png"/>
            </div>
            <p style="font-size: 16px;">AerospaceNZ is New Zealand’s national industry body for the Aerospace sector whose goal is to build an aerospace nation. </p>
          </div>
        </div>
      </div>
    </Container>

    <SimplifiedFooter :with-margin="false"/>
  </div>
</template>

<script lang="ts">
  import SimpleHero from '../SimpleHero/SimpleHero.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import { fetchPublicNotifications } from '../../api/notifications.js'
  import DsButton from '../DsButton/DsButton.vue'
  import SimplifiedCommunityCard from '../Simplified/SimplifiedCommunityCard.vue'
  import { getDefaultFallbackImageUrl, getImageForCommunity } from '../../util/helpers.js'
  import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
  import { fetchStatsData } from '../../api/homepage.js'
  import Container from '../Container/Container.vue'
  import { getFormattedEventDate } from '../../util/date.js'
  import moment from 'moment'
  import { defineComponent } from "vue";
  import { trackMatomoEvent } from '@/util/analytics'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'
  import FacetCard from '@/pages/Simplified/FacetCard.vue'
  import { getTranslationsForTaxonomyValue } from '@/store/modules/taxonomies'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '@/store/modules/filters'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '@/store/modules/actors'
  import AnnouncementOrResourceCard from '@/components/Simplified/AnnouncementOrResourceCard.vue'
  import { fetchKnowledgeBaseData, likeArticle } from '@/api/knowledge-base'
  import { Carousel, Navigation, Slide } from 'vue3-carousel'
  import CarouselMixin from '@/util/CarouselMixin'
  import { fetchActors } from '@/api/actors'
  import ecosystem from '../Settings/Ecosystem.vue'

  export default defineComponent({
    mixins: [CarouselMixin],
    data: () => {
      return {
        events: [],
        announcements: [],
        fetchingEvents: false,
        fetchingAnnouncements: false,
        latestActors: [],
        latestProducts: [],
        allActors: [],
        facetCounts: [],
        resources: [],
        clusterCardData: [
          {
            title: 'Explore & Connect',
            description: 'Find interesting high-quality contacts, with extensive experience and working in innovation ecosystems worldwide.',
            image: "/images/static-home-page/ec-health/1.png",
            buttonHref: '/products-simplified',
            buttonText: 'view more',
          },
          {
            title: 'Stories & Events',
            description: 'Read the stories of peers and join our events to connect with interesting parties.',
            image: "/images/static-home-page/ec-health/2.png",
            buttonHref: '/announcements',
            buttonText: 'view more',
          },
          {
            title: 'Resources & Support',
            description: 'Interact with interesting partners to ask questions and share your ideas and discuss earlier insights.',
            image: "/images/static-home-page/ec-health/3.png",
            buttonHref: '/announcements',
            buttonText: 'view more',
          },
        ],
      }
    },
    computed: {
      whoIsPlatformForDescription() {
        if (this.$store.getters.isEcHealth) {
          return 'In just a few clicks, our platform unlocks access to a robust network dedicated to accelerating the\n' +
            '            development of innovative\n' +
            '            health solutions and driving meaningful change for patients, communities, and organisations. We simplify the\n' +
            '            search for\n' +
            '            connections, helping you overcome the challenges of networking through traditional channels like LinkedIn or\n' +
            '            industry events.'
        } else if (this.$store.getters.isEcAerospace) {
          return 'This platform provides you access to a network of people, organisations, resources and news across the aerospace sector in Aotearoa New Zealand.'
        }
      },
      whoIsPlatformForTitle() {
        if (this.$store.getters.isEcHealth) {
          return 'Who is Canterbury Health <br> Innovation for'
        } else if (this.$store.getters.isEcAerospace) {
          return 'How you can use the New Zealand Aerospace Ecosystem Platform '
        }
      },
      whoWeAreTitle() {
        if (this.$store.getters.isEcHealth) {
          return 'The Canterbury Health Innovation Ecosystem is powered by a network of partners'
        } else if (this.$store.getters.isEcAerospace) {
          return 'The New Zealand Aerospace Ecosystem Platform is powered by a network of partners'
        }
      },
      whyUniqueTitle() {
        if (this.$store.getters.isEcHealth) {
          return 'Why Canterbury Health Innovation is unique'
        } else if (this.$store.getters.isEcAerospace) {
          return 'Why the New Zealand Aerospace Ecosystem Platform is unique'
        }
      },
      whyUniqueText() {
        if (this.$store.getters.isEcHealth) {
          return 'We accelerate health innovation in Canterbury by: \n' +
            '\n<ul style="list-style-type: circle; margin-left: 30px;"><li>' +
            'Connecting innovators with world-class expertise, facilities, and partners \n' +
            '\n</li><li>' +
            'Providing pathways to bring innovations from concept to market \n' +
            '\n</li><li>' +
            'Building a community of leaders who drive meaningful healthcare solutions \n' +
            '\n</li><li>' +
            'Supporting the growth of globally competitive health technology companies</li>' +
            '\n</ul>\n' + '</ul>'
        } else if (this.$store.getters.isEcAerospace) {
          return 'We accelerate aerospace innovation in New Zealand by: \n' +
            '\n<ul style="list-style-type: circle; margin-left: 30px;"><li>' +
            'Connecting innovators with world-class expertise, facilities, and partners \n' +
            '\n</li><li>' +
            'Providing pathways to bring innovations from concept to market \n' +
            '\n</li><li>' +
            'Building a community of leaders who drive innovation \n' +
            '\n</li><li>' +
            'Supporting the growth of globally competitive aerospace technology companies</li>'
        }
      },
      cardData() {
        if (this.$store.getters.isEcHealth) {
          return [
            {
              title: 'Startups & Scaleups',
              description: 'Access resources, mentorship, and partners to bring your health solutions to market.',
              subtitle: 'Latest added',
              image: "/images/static-home-page/ec-health/startups_and_scaleups.png",
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
            {
              title: 'Industry Partners',
              description: 'Discover breakthrough technologies and collaborate with Canterbury\'s leading health innovators.',
              subtitle: 'Latest added',
              image: "/images/static-home-page/ec-health/corporates.png",
              buttonHref: '/products-simplified',
              buttonText: 'view more',
            },
            {
              title: 'Researchers & Clinicians',
              description: 'Connect with industry partners and access facilities to translate your research into real-world impact.',
              subtitle: 'Latest added',
              image: "/images/static-home-page/ec-health/knowledge_institutes.png",
              buttonHref: '/announcements',
              buttonText: 'view more',
            },
            {
              title: 'Support Organizations',
              description: 'Engage with a vibrant ecosystem supporting the acceleration of world-class health solutions and the pioneers behind them.',
              subtitle: 'Latest added',
              image: "/images/static-home-page/ec-health/good.png",
              buttonHref: '/announcements',
              buttonText: 'view more',
            },
          ]
        } else if (this.$store.getters.isEcAerospace) {
          return [
            {
              title: 'Startups & Scaleups',
              description: 'Access resources, mentorship, and partners to bring your aerospace solutions to market.',
              subtitle: 'Latest added',
              image: "/images/static-home-page/ec-health/startups_and_scaleups.png",
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
            {
              title: 'Industry Partners',
              description: 'Discover breakthrough technologies and collaborate with New Zealand’s leading aerospace innovators.',
              subtitle: 'Latest added',
              image: "/images/static-home-page/ec-health/corporates.png",
              buttonHref: '/products-simplified',
              buttonText: 'view more',
            },
            {
              title: 'Researchers',
              description: 'Connect with industry partners and access facilities to translate your research into real-world impact.',
              subtitle: 'Latest added',
              image: "/images/static-home-page/ec-health/knowledge_institutes.png",
              buttonHref: '/announcements',
              buttonText: 'view more',
            },
            {
              title: 'Support Organizations',
              description: 'Engage with a vibrant ecosystem supporting the acceleration of aerospace solutions and the pioneers behind them.',
              subtitle: 'Latest added',
              image: "/images/static-home-page/ec-health/good.png",
              buttonHref: '/announcements',
              buttonText: 'view more',
            },
          ]
        }
      },
      registerAndExploreTitle() {
        if (this.$store.getters.isEcHealth) {
          return 'Register & Explore in <br> just a few clicks'
        } else if (this.$store.getters.isEcAerospace) {
          return 'Register & Explore'
        }
      },
      registerAndExploreDescription() {
        if (this.$store.getters.isEcHealth) {
          return 'Connect, innovate, and grow with Canterbury\'s premier health innovation community. Our platform brings together innovators, industry partners, researchers, clinicians, and support organisations to accelerate the development of breakthrough health solutions.'
        } else if (this.$store.getters.isEcAerospace) {
          return 'Connect, innovate, and grow with New Zealand’s premier aerospace innovation community. Our platform brings together innovators, industry partners, researchers and support organisations to accelerate the development of the New Zealand aerospace sector.'
        }
      },
      actorNumbersTitle() {
        if (this.$store.getters.isEcHealth) {
          return 'Number of Members in the Canterbury Health Innovation community'
        } else if (this.$store.getters.isEcAerospace) {
          return 'The New Zealand Aerospace Ecosystem at a glance'
        }
      },
      ecosystem() {
        return ecosystem
      },
      ecosystemDisplayName(): string {
        return this.$store.getters.ecosystemDisplayName
      },
      linkToMapData() {
        if (this.$store.getters.isEcHealth) {
          return {
            title: "MedTechIQ AeroSpaceNZ Map",
            description: "The MedTechIQ AeroSpaceNZ Map is a detailed visualization of key locations and organizations driving innovation in New Zealand's aerospace and medical technology sectors. Designed to provide clear insights into the regional landscape, this map serves as a valuable reference for professionals, researchers, and stakeholders seeking to navigate and connect with the industry's most impactful hubs.",
            url: "https://static.datascouts.eu/medtech-iq-cc-focus.html",
            buttonText: "Visit Map",
          }
        } else if (this.$store.getters.isEcAerospace) {

        } else if (this.$store.getters.isEcCleantech) {

        } else if (this.$store.getters.isAgritechHub) {

        } else {
          return {
            title: "MedTechIQ AeroSpaceNZ Map",
            description: "The MedTechIQ AeroSpaceNZ Map is a detailed visualization of key locations and organizations driving innovation in New Zealand's aerospace and medical technology sectors. Designed to provide clear insights into the regional landscape, this map serves as a valuable reference for professionals, researchers, and stakeholders seeking to navigate and connect with the industry's most impactful hubs.",
            url: "https://static.datascouts.eu/medtech-iq-cc-focus.html",
            buttonText: "Visit Map",
          }
        }
      },
      getFirstColumnActorPortfolio() {
        if (this.$store.getters.isEcHealth) {
          return "b3koWA8kWr"
        } else if (this.$store.getters.isEcAerospace) {
          return ""
        } else if (this.$store.getters.isEcCleantech) {
          return ""
        } else if (this.$store.getters.isAgritechHub) {
          return ""
        } else {
          return "wVdp1MPpaE"
        }
      },
      getSecondCardActorPortfolio() {
        if (this.$store.getters.isEcHealth) {
          return "L0V4v3Lkb7"
        } else if (this.$store.getters.isEcAerospace) {
          return ""
        } else if (this.$store.getters.isEcCleantech) {
          return ""
        } else if (this.$store.getters.isAgritechHub) {
          return ""
        } else {
          return "wVdp1MPpaE"
        }
      },
      getThirdColumnActorPortfolio() {
        if (this.$store.getters.isEcHealth) {
          return "wVdp1MPpaE"
        } else if (this.$store.getters.isEcAerospace) {
          return ""
        } else if (this.$store.getters.isEcCleantech) {
          return ""
        } else if (this.$store.getters.isAgritechHub) {
          return ""
        } else {
          return "wVdp1MPpaE"
        }
      },
      highlightedSpottingArea() {
        return this.$store.state.spottingAreas.highlightedSpottingArea.data
      },
      highlightedSpottingAreaImage() {
        return getDefaultFallbackImageUrl()
      },
      registerButtonStyle() {
        return {
          backgroundColor: '#21244A',
          color: 'white',
          fontFamily: '',
          fontWeight: 'bold',
          height: 'auto',
          width: '',
          textTransform: 'capitalize',
          borderRadius: '4px',
          alignSelf: 'center',
          textAlign: 'center',
          marginTop: '50px',
          fontSize: '16px',
        }
      },
      hasAccessToCommunities() {
        return this.$store.getters.hasAccessToCommunities
      },
      communities() {
        // return this.$store.state.communities.listData.data.slice(0, 3)
        return this.$store.state.communities.listData.data
      },
    },
    methods: {
      likeArticle({ resourceId, status }) {
        likeArticle(resourceId, status)
      },
      fetchLatestNews() {
        return fetchKnowledgeBaseData({ limit: 10, offset: 0, relevantOnly: true, tags: 871 })
          .then(news => {
            var items = news
              .data.results
              .map(response => {
                if (['rss', 'pr', 'blog'].includes(response.media_type) && response.sql_media_id) {
                  response.detail_link = '/files/' + response.sql_media_id
                }
                return response
              })

            this.resources = items
          })
          .catch(error => {
            console.log(error)
          })
      },
      facetCardClick(facet) {
        if (!this.$store.getters.isLoggedIn) {
          this.$router.push('/register')

          return;
        }

        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
        this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
          facet: facet.facetType,
          value: facet.value,
        })

        const listFilters = { ...this.$store.getters.listFilterObject }

        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)

        if (this.isMember) {
          this.$router.push('/actors')
        } else {
          this.$router.push('/actors-simplified')
        }
      },
      fetchFacetCounts() {
        const parameters = {}

        fetchStatsData(parameters)
          .then(response => {
            const resultOrdered = response.data.stats.map(result => {
              const translations = getTranslationsForTaxonomyValue(response.data.property, result.value)
              let label = ''

              if (translations && translations[this.locale] && translations[this.locale].name) {
                label = translations[this.locale].name
              } else {
                label = translations['en'].name
              }

              result['label'] = label
              result['count'] = result.total
              result['facetName'] = response.data.property

              return result
            })

            // Only return the top 6
            this.facetCounts = resultOrdered
              .sort((a, b) => b.count - a.count)
              .slice(0, 6)
          })
          .catch(errors => {
            //
          })
      },
      goToHighlightedSpottingAreaDetail() {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.HIGHLIGHTED_SPOTTING_AREA, MATOMO_EVENT_ACTIONS.HOMEPAGE_BUTTON, this.highlightedSpottingArea.id)
        this.$router.push(`/spotting-areas/${this.highlightedSpottingArea.id}`)
      },
      getFormatEventDate: getFormattedEventDate,
      getImageForCommunity,
      getDefaultFallbackImageUrl,
      goToActorDetailPage(actor) {
        this.$router.push(`/actors/${actor.id}`)
      },
      goToAnnouncementDetailPage(announcement) {
        this.$router.push(`/announcements/${announcement.slug || announcement.id}`)
      },
      goToViewMore(data) {
        this.$router.push(data.buttonHref)
      },
      goToEvents() {
        this.$router.push('/events-simplified')
      },
      goToAnnouncements() {
        this.$router.push('/announcements-simplified')
      },
      fetchEvents() {
        this.fetchingEvents = true

        return fetchPublicNotifications(1, 0, { is_event: true, upcoming_events: true })
          .then(response => {
            this.events = response
            this.fetchingEvents = false
          })
          .catch(error => {
            this.fetchingEvents = false

            console.log(error)
          })
      },
      fetchAnnouncements() {
        this.fetchingAnnouncements = true

        return fetchPublicNotifications(1, 0, { is_event: false })
          .then(response => {
            this.announcements = response
            this.fetchingAnnouncements = false
          })
          .catch(error => {
            this.fetchingAnnouncements = false

            console.log(error)
          })
      },
      fetchLatestActors() {
        const actorsParameters = { limit: 3, portfolio: this.getFirstColumnActorPortfolio } // b3koWA8kWr

        fetchActors(actorsParameters)
          .then((actors) => {
            this.latestActors = actors.data
          })
      },
      fetchLatestProducts() {
        const actorsParameters = { limit: 3, portfolio: this.getSecondCardActorPortfolio } // L0V4v3Lkb7

        fetchActors(actorsParameters)
          .then((actors) => {
            this.latestProducts = actors.data
          })
      },
      fetchAllActors() {
        const actorsParameters = { limit: 3, portfolio: this.getThirdColumnActorPortfolio } // aAVbz5OVmr

        fetchActors(actorsParameters)
          .then((actors) => {
            this.allActors = actors.data
          })
      },
    },
    async mounted() {
      this.fetchLatestActors()
      this.fetchLatestProducts()
      this.fetchAllActors()
      this.fetchEvents()
      this.fetchFacetCounts()
      this.fetchAnnouncements()
      this.fetchLatestNews()
    },
    components: {
      AnnouncementOrResourceCard,
      FacetCard,
      SimplifiedCommunityCard,
      SimpleHero,
      Avatar,
      DsButton,
      SimplifiedFooter,
      Container,
      Carousel,
      Slide,
      Navigation
    },
  })
</script>


<style lang="scss" scoped>
  @import "resources/assets/scss/variables";
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

  :deep(.category-stats-title) {
    font-size: 14px !important;
    font-family: "DM Sans", sans-serif !important;
  }

  .dm-sans-font {
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
  }

  .mt-4rem {
    margin-top: 4rem;
  }

  .cnz.scrollable {
    h1 {
      font-size: 48px;
      font-family: "DM Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      font-size: 16px;
    }

    p {
      font-size: 18px;
      font-family: "Work Sans", sans-serif;
    }

    .mb-05 {
      margin-bottom: 2rem;
    }
  }

  .cnz-orchestrators {
    width: 100%;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;

    .image-container-ec-aerospace {
      margin-top: 3rem;

      .cnz_who_section {
        justify-content: space-evenly;
        max-width: 1300px;
        margin: 2rem auto 0px;

        .cnz_who_section--card {
          display: flex !important;
          flex-direction: column;
          align-content: center;
          text-align: center;
          height: auto !important;

          &:first-child {
            img {
              padding: 25px;
            }
          }

          .cnz_who_section_image_container {
            height: 100px;
          }
        }
      }
    }


    .image-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 50%;
      margin: 0 auto;

      img {
        &:first-child {
          width: 250px;
          height: 50px;
        }

        align-self: center;
        width: 200px;
      }
    }
  }

  .cnz_who_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .cnz_who_section--card.cluster {
      box-shadow: 0 0 20px 0 rgba(27, 50, 67, .15);
      max-width: 400px;
      height: 400px;
      max-height: 450px;

      img {
        width: 200px;
      }
    }

    .cnz_who_section--card {
      text-align: center;
      background-color: white;
      border-radius: 8px;
      padding: 1rem;
      max-width: 300px;
      margin-bottom: 1rem;

      h4 {
        margin-bottom: 1rem;
      }
    }
  }

  .cnz__info--container {
    margin-bottom: 2rem;
  }

  .cnz__info {
    .cnz__cards {
      display: flex;
      justify-content: center;
      margin-top: -85px;
      gap: 2rem;

      .cnz__card {
        width: 400px;
        max-width: 340px;
        background-color: #fff0f0;
        filter: drop-shadow(0 3px 6px rgba(#000, 0.16));
        height: 100%;
        border-radius: 8px;
        padding: 30px 20px 20px 20px;
        position: relative;

        .cnz__card_content {
          margin-top: 20px;

          .avatar {
            background-color: rgba(33, 36, 74, .20) !important;
          }
        }

        h2 {
          font-weight: 500;
          color: black;
        }

        .cnz__card_item {
          display: flex;
          margin-bottom: 25px;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          p {
            margin: 0 10px;
            color: black;
            font-size: 14px;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .avatar {
            width: 35px;
            height: 35px;
            background-color: rgba(33, 36, 74, .20) !important;

            :deep(.avatar__img) {
              width: 35px;
              height: 35px;
            }
          }
        }

        p {
          margin: 20px 0 20px 0;
          color: #788088;
        }

        .static-home-foodleap__card_button {
          text-decoration: underline;
          cursor: pointer;

          p {
            color: black;
          }
        }
      }
    }
  }


  .cnz__upcoming-event {
    //background-color: var(--primary-lightest);

    background-color: #231F20;

    .cnz_events {
      padding: 3em 1em;
      color: white;

      .cnz_last_event.reverse {
        flex-direction: row-reverse;
      }

      .cnz_last_event {
        display: flex;
        color: black;

        .cnz_card_info {
          h1:first-child {
            text-transform: uppercase;
            color: #707070;
            font-weight: 400;
            font-size: 24px;
          }

          h1:nth-child(2) {
            margin-top: 10px;
          }

          .cnz__date {
            display: flex;
            align-items: center;
            margin-top: 10px;

            p {
              margin-left: 10px;
              font-size: 14px;
            }
          }

          .cnz_description {
            color: #707070;
            margin-top: 20px;

            p {
              white-space: pre-line;
              width: 97%;
              font-size: 14px;
            }
          }
        }

        .cnz_image {
          margin-top: 20px;

          img {
            border: 8px solid white;
          }
        }
      }
    }
  }

  .cnz__communities {
    margin-top: 5rem;

    h1 {
      color: black;
      text-align: center;
    }

    p {
      text-align: center;
      margin-top: 20px;
      color: #707070;
    }

    .cnz__communities_list {
      width: 340px;
    }

    .cnz__communities_list_view {
      overflow-y: auto;
      padding: 20px;
      display: flex;
      margin-top: 3rem;
      gap: 2rem;
    }
  }

  .cnz {
    @media(max-width: $screen-xl) {
      .cnz__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
      }

      .cnz__communities_list_view {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
      }
    }

    @media(max-width: $screen-lg) {
      .cnz__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        margin-top: -100px;
        align-items: center;

        .cnz__card {
          width: 100% !important;
          margin: 20px;
          max-width: 100% !important;
        }
      }

      .cnz_events {
        display: flex;
        flex-direction: column;
        padding: 3em 2em;

        .cnz_last_event {
          display: flex;
          flex-direction: column;

          .cnz_card_info .cnz_description p {
            width: 100%;
          }
        }

        .cnz_image {
          margin-top: 20px;
        }

        .static-home-foodleap_information {
          p {
            width: 100%;

          }
        }
      }

      .cnz__communities_list {
        width: 300px;
      }

      .cnz__communities_list_view {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
      }

    }
  }

  @media(max-width: $screen-md-max) {
    .cnz__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      align-items: normal !important;

      .cnz__card {
        width: 100% !important;
        margin: 20px;
      }
    }

    .cnz_events {
      display: flex;
      flex-direction: column;
      padding: 3em 2em;

      .cnz_last_event {
        display: flex;
        flex-direction: column;

        .cnz_card_info .cnz_description p {
          width: 100%;
        }
      }

      .cnz_image {
        margin-top: 20px;
      }

      .static-home-foodleap_information {
        p {
          width: 100%;

        }
      }
    }

    .cnz__communities_list {
      width: 200px;
    }

    .cnz__communities_list_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }
  }

  .spotting-areas-highlighted__section__container {
    display: flex;
    flex-direction: row;

    padding-top: 50px;
    padding-bottom: 50px;
  }

  .spotting-areas-highlighted__section {
    position: relative;
    flex-wrap: wrap;

    .spotting-areas-highlighted__background {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      width: 100vw;
      height: 100%;
      background-color: var(--primary-extra-lightest); /* Vervang dit door jouw gewenste kleurcode */
      z-index: -1;
    }

    .spotting-areas-highlighted {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--primary-extra-lightest);
      position: relative;
      padding-bottom: 50px;

      .highlight-spotting-area-button {
        margin-left: 0;
        padding: 10px 15px 10px 15px !important;
      }

      :deep(ul) {
        list-style-type: disc !important;
        margin-left: 15px;
      }

      .highlighted_spotting-area__content {

        &.image {
          padding-top: 50px;
        }

        h1 {
          margin-bottom: 12px;
        }

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 12;
          position: relative;
          text-overflow: ellipsis;


          max-height: 300px;
          list-style-type: disc;
        }
      }
    }
  }

  .platform-benefit {
    flex: 1;
    box-shadow: 0 0 20px 0 rgba(27, 50, 67, .15);
    padding: 1.5rem;
    border-radius: 8px;

    h2 {
      margin-bottom: 1rem;
    }
  }

  .platform-benefits {
    margin-bottom: 4rem;

    .platform-benefits-container {
      display: flex;
    }


    p {
      text-decoration: underline;
      margin-bottom: 0;
      margin-top: 2rem;
      font-weight: bold;
    }

    span {
      margin-bottom: 2rem;
      font-size: 16px !important;
    }
  }

  .platform-benefits-container {
    gap: 3rem; /* Adjust the gap between columns */
    justify-content: space-between;
  }

  .platform-benefit {
    box-sizing: border-box;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    .platform-benefits-container {
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: center; /* Center the content */
    }

    .cnz_who_section {
      margin: 2rem auto !important;
      width: 400px;
    }

    .platform-benefit {
      width: 100%; /* Each item will take up 80% of the container width */
      margin: 0 auto;

      .button {
        margin-top: 1rem;
      }
    }

    .platform-benefit.big {
      margin-top: 3rem;
    }
  }

  @media (max-width: 480px) {
    .platform-benefits-container {
      align-items: center; /* Center the content even further for very small screens */
    }

    .platform-benefit {
      width: 90%; /* Items will take up 90% of the container width */
    }
  }

</style>
