<template>
  <div class="foodvalley scrollable">
    <SimpleHero :is-foodleap="true"/>
    <Container>
      <div class="foodvalley__info">
        <div class=" foodvalley__cards">
          <div v-for="data in cardData" class="col-xs-12 col-sm-10 col-md-6 col-lg-4 foodvalley__card"
               style="color: #21244A;">
            <h2>{{ data.title }}</h2>
            <p style="height: 70px;">{{ data.description }}</p>
            <h4>{{ data.subtitle }}</h4>
            <div class="foodvalley__card_content" v-if="data.key === 'latest_actors'">
              <div v-for="item in latestActors" class="foodvalley__card_item" @click="goToActorDetailPage(item)">
                <avatar :src="item.logo" :alt="item.name" variant="border"></avatar>
                <p style="font-weight: 700;">{{ item.name }}</p>
              </div>
            </div>
            <div class="foodvalley__card_content" v-else-if="data.key === 'latest_products'">
              <div v-for="item in latestProducts" class="foodvalley__card_item" @click="goToActorDetailPage(item)">
                <avatar :src="item.featured_image_url" :alt="item.name" variant="border"></avatar>
                <p style="font-weight: 700;">{{ item.name }}</p>
              </div>
            </div>
            <div class="foodvalley__card_content" v-else-if="data.key === 'latest_announcements'">
              <div v-for="item in latestAnnouncements" class="foodvalley__card_item"
                   @click="goToAnnouncementDetailPage(item)">
                <avatar :src="`/notifications/${item.id}/image`" :alt="item.title" variant="border"></avatar>
                <p style="font-weight: 700;">{{ item.title }}</p>
              </div>
            </div>
            <div class="static-home-foodleap__card_button" @click="goToViewMore(data)">
              <p style="margin: 0;">{{ data.buttonText }}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>

    <div v-if="!fetchingEvents" class="foodvalley__upcoming-event">
      <Container>
        <div class="foodvalley_events">
          <div v-if="events.length === 0">
            <div class="foodvalley_last_event">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 foodvalley_card_info">
                <h1>{{ $t('home_foodvalley_event_title') }}</h1>
                <h4>There are no upcoming events right now.</h4>
                <ds-button
                  variant="secondary"
                  :label="$t('home_foodvalley_see_all_events')"
                  :size="null"
                  :style="registerButtonStyle"
                  @click="goToEvents"
                />
              </div>
            </div>
          </div>
          <div v-for="item in events" class="foodvalley_last_event">
            <div class="col-12 col-sm-12 col-md-7 col-lg-7 foodvalley_card_info">
              <h1>{{ $t('home_foodvalley_event_title') }}</h1>
              <h1>{{ item.title }}</h1>
              <div class="foodvalley__date">
                <icon name="calendar-empty" size="22"/>
                <p v-html="getFormatEventDate(item, true)"></p>
              </div>
              <div class="foodvalley_description">
                <p>{{ item.shortDescription }}</p>
                <a :href="'/events/' + (item.slug || item.id)"
                   target="_blank">{{ $t('home_foodvalley_description_button') }}</a>
              </div>
              <ds-button
                variant="secondary"
                :label="$t('home_foodvalley_see_all_events')"
                :size="null"
                :style="registerButtonStyle"
                @click="goToEvents"
              />
            </div>
            <div v-if="item.image" class="col-12 col-sm-12 col-md-5 col-lg-5 foodvalley_image">
              <img :src="`/notifications/${item.id}/image`" alt="Event Image"/>
            </div>
            <div v-else class="col-12 col-sm-12 col-md-5 col-lg-5 foodvalley_image">
              <img :src="getDefaultFallbackImageUrl()" alt="Event Image"/>
            </div>
          </div>
        </div>
      </Container>
    </div>

    <Container>
      <div v-if="hasAccessToCommunities" class="foodvalley__communities">
        <h1>{{ $t('home_foodvalley_communities_title') }}</h1>
        <p>{{ $t('home_foodvalley_communities_description') }}</p>

        <div class="foodvalley__communities_list_view">
          <div v-for="item in communities" class="foodvalley__communities_list">
            <div class="foodvalley__communities_cards">
              <SimplifiedCommunityCard
                style="min-width: 340px;"
                :title="item.name" :description="item.description" :image="getImageForCommunity(item)"
                :button-text="$t('home_foodvalley_description_button')"
                :href="'/communities/' + item.id"/>
            </div>
          </div>
        </div>
      </div>
    </Container>

    <Container>
      <template v-if="highlightedSpottingArea.id">
        <div class="spotting-areas-highlighted__section" style="margin-top: 50px;">
          <div class="spotting-areas-highlighted__background"></div>
          <div class="spotting-areas-highlighted__section__container">
            <div class="spotting-areas-highlighted__section" style="width: 48%">
              <div class="spotting-areas-title">
                {{ highlightedSpottingArea.name }}
              </div>
              <div class="spotting-areas-highlighted">
                <div class="highlighted_spotting-area__content">
                  <p v-html="highlightedSpottingArea.description"></p>
                  <ds-button
                    class="highlight-spotting-area-button"
                    icon="location-marker"
                    :icon-fill-color="'#ffffff'"
                    v-if="highlightedSpottingArea.id"
                    :style="{backgroundColor: highlightedSpottingArea.highlight_color}"
                    @click="goToHighlightedSpottingAreaDetail"
                    :label="highlightedSpottingArea.name"
                  ></ds-button>
                </div>
              </div>
            </div>
            <div
              v-if="(highlightedSpottingAreaImage || getDefaultFallbackImageUrl())"
              :style="{ backgroundImage: `url(${highlightedSpottingAreaImage || getDefaultFallbackImageUrl()})` }"
              class="highlighted_spotting-area__content image"
              style="width: 50%; height: 350px; background-repeat: round; align-self: center;margin-left: 30px;"
              :class="{ 'full-size': true, 'fallback-image': !highlightedSpottingAreaImage}"
            >
              <div
                v-if="(highlightedSpottingAreaImage || getDefaultFallbackImageUrl())"
                :style="{ backgroundImage: `url(${highlightedSpottingAreaImage || getDefaultFallbackImageUrl()})`}"
                class="simplified-connection__logo"
                :class="{ 'full-size': true, 'fallback-image': !highlightedSpottingAreaImage}"
              />
            </div>
          </div>
        </div>
      </template>
    </Container>

    <SimplifiedFooter :with-margin="false"/>
  </div>
</template>

<script lang="ts">
  import SimpleHero from '../SimpleHero/SimpleHero.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import { fetchPublicNotifications } from '../../api/notifications.js'
  import DsButton from '../DsButton/DsButton.vue'
  import SimplifiedCommunityCard from '../Simplified/SimplifiedCommunityCard.vue'
  import { getDefaultFallbackImageUrl, getImageForCommunity, getImageForSpottingArea } from '../../util/helpers.js'
  import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
  import { fetchLatestActors } from '../../api/homepage.js'
  import Container from '../Container/Container.vue'
  import { getFormattedEventDate } from '../../util/date.js'
  import { defineComponent } from "vue";
  import { trackMatomoEvent } from '@/util/analytics'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'

  export default defineComponent({
    data: () => {
      return {
        events: [],
        fetchingEvents: false,
        latestActors: [],
        latestProducts: [],
        latestAnnouncements: [],
        cardData: [
          {
            key: 'latest_actors',
            title: 'Find your partner',
            description: 'Find interesting high quality contacts. with extensive experience and working in innovation ecosystems worldwide.',
            subtitle: 'Latest added',
            partners: [
              /* {
                image: '/images/static-home-page/Digital_society.jpeg',
                text: 'Wageningen university & research',
              },
              {
                image: '/images/static-home-page/Digital_society.jpeg',
                text: 'Startlife',
              },
              {
                image: '/images/static-home-page/Digital_society.jpeg',
                text: 'FitSurance',
              }, */
            ],
            buttonHref: '/actors-simplified',
            buttonText: 'view more',
          },
          {
            key: 'latest_products',
            title: 'Find your innovation facility',
            description: 'Search for innovation facilities to speed up time to market or share your spare capacity with others and connect with interesting partners.',
            subtitle: 'Latest added',
            partners: [
              /* {
                image: '/images/static-home-page/Digital_society.jpeg',
                text: 'Wageningen university & research',
              },
              {
                image: '/images/static-home-page/Digital_society.jpeg',
                text: 'Startlife',
              },
              {
                image: '/images/static-home-page/Digital_society.jpeg',
                text: 'FitSurance',
              }, */
            ],
            buttonHref: '/products-simplified',
            buttonText: 'view more',
          },
          {
            key: 'latest_announcements',
            title: 'Interact and share',
            description: 'Interact with interesting partners to ask questions and share your ideas and discuss earlier findings.',
            subtitle: 'Latest added',
            partners: [
              /* {
                image: '/images/static-home-page/Digital_society.jpeg',
                text: 'Wageningen university & research',
              },
              {
                image: '/images/static-home-page/Digital_society.jpeg',
                text: 'Startlife',
              },
              {
                image: '/images/static-home-page/Digital_society.jpeg',
                text: 'FitSurance',
              }, */
            ],
            buttonHref: '/announcements',
            buttonText: 'view more',
          },
        ],

      }
    },
    computed: {
      highlightedSpottingArea() {
        return this.$store.state.spottingAreas.highlightedSpottingArea.data
      },
      highlightedSpottingAreaImage() {
        return getImageForSpottingArea(this.highlightedSpottingArea)
      },
      registerButtonStyle() {
        return {
          backgroundColor: '#21244A',
          color: 'white',
          fontFamily: '',
          fontWeight: 'bold',
          height: 'auto',
          width: '',
          textTransform: 'capitalize',
          borderRadius: '4px',
          alignSelf: 'center',
          textAlign: 'center',
          marginTop: '50px',
          fontSize: '16px',
        }
      },
      hasAccessToCommunities() {
        return this.$store.getters.hasAccessToCommunities
      },
      communities() {
        // return this.$store.state.communities.listData.data.slice(0, 3)
        return this.$store.state.communities.listData.data
      },
    },
    methods: {
      goToHighlightedSpottingAreaDetail() {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.HIGHLIGHTED_SPOTTING_AREA, MATOMO_EVENT_ACTIONS.HOMEPAGE_BUTTON, this.highlightedSpottingArea.id)
        this.$router.push(`/spotting-areas/${this.highlightedSpottingArea.id}`)
      },
      getFormatEventDate: getFormattedEventDate,
      getImageForCommunity,
      getDefaultFallbackImageUrl,
      goToActorDetailPage(actor) {
        this.$router.push(`/actors/${actor.id}`)
      },
      goToAnnouncementDetailPage(announcement) {
        this.$router.push(`/announcements/${announcement.slug || announcement.id}`)
      },
      goToViewMore(data) {
        this.$router.push(data.buttonHref)
      },
      goToEvents() {
        this.$router.push('/events-simplified')
      },
      fetchEvents() {
        this.fetchingEvents = true

        return fetchPublicNotifications(1, 0, { is_event: true, upcoming_events: true })
          .then(response => {
            this.events = response
            this.fetchingEvents = false
          })
          .catch(error => {
            this.fetchingEvents = false

            console.log(error)
          })
      },
      fetchLatestActors() {
        const actorsParameters = { limit: 3 }

        fetchLatestActors(actorsParameters).then((actors) => {
          this.latestActors = actors
        })
      },
      fetchLatestProducts() {
        const productsParameters = { limit: 3, actor_type: 'Product' }
        fetchLatestActors(productsParameters).then((actors) => {
          this.latestProducts = actors
        })
      },
      fetchLatestAnnouncements() {
        fetchPublicNotifications(3, 0, { is_event: false })
          .then(items => {
            this.latestAnnouncements = items || []
          })
      },
    },
    async mounted() {
      this.fetchEvents()
      this.fetchLatestActors()
      this.fetchLatestProducts()
      this.fetchLatestAnnouncements()
    },
    components: {
      SimplifiedCommunityCard,
      SimpleHero,
      Avatar,
      DsButton,
      SimplifiedFooter,
      Container,
    },
  })
</script>


<style lang="scss" scoped>
  @import "resources/assets/scss/variables";

  .foodvalley__info {
    margin-bottom: 4em;
    font-family: Museo, serif;

    p {
      font-family: 'Zen Kaku Gothic New', sans-serif;
    }

    .foodvalley__cards {
      display: flex;
      justify-content: center;
      margin-top: -85px;
      gap: 2rem;

      .foodvalley__card {
        width: 400px;
        max-width: 340px;
        background-color: #F2F9FE;
        filter: drop-shadow(0 3px 6px rgba(#000, 0.16));
        height: 100%;
        border-radius: 8px;
        padding: 30px 20px 20px 20px;
        position: relative;

        .foodvalley__card_content {
          margin-top: 20px;

          .avatar {
            background-color: rgba(33, 36, 74, .20) !important;
          }
        }

        h2 {
          font-weight: 500;
          color: #21244A;
        }

        .foodvalley__card_item {
          display: flex;
          margin-bottom: 25px;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          p {
            margin: 0 10px;
            color: #21244A;
            font-size: 14px;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .avatar {
            width: 35px;
            height: 35px;
            background-color: rgba(33, 36, 74, .20) !important;

            :deep(.avatar__img) {
              width: 35px;
              height: 35px;
            }
          }
        }

        p {
          margin: 20px 0 20px 0;
          color: #788088;
        }

        .static-home-foodleap__card_button {
          text-decoration: underline;
          cursor: pointer;

          p {
            color: #21244A;
          }
        }
      }
    }
  }

  .foodvalley__upcoming-event {
    background-color: #DAE1DA;

    .foodvalley_events {
      padding: 3em 1em;

      .foodvalley_last_event {
        display: flex;

        .foodvalley_card_info {
          h1:first-child {
            text-transform: uppercase;
            color: #707070;
            font-weight: 400;
            font-size: 24px;
          }

          h1:nth-child(2) {
            margin-top: 10px;
          }

          .foodvalley__date {
            display: flex;
            align-items: center;
            margin-top: 10px;

            p {
              margin-left: 10px;
              font-size: 14px;
            }
          }

          .foodvalley_description {
            color: #707070;
            margin-top: 20px;

            p {
              white-space: pre-line;
              width: 97%;
              font-size: 14px;
            }
          }
        }

        .foodvalley_image {
          margin-top: 20px;

          img {
            border: 8px solid white;
          }
        }
      }
    }
  }

  .foodvalley__communities {
    margin-top: 5rem;

    h1 {
      color: #21244A;
      text-align: center;
    }

    p {
      text-align: center;
      margin-top: 20px;
      color: #707070;
    }

    .foodvalley__communities_list {
      width: 340px;
    }

    .foodvalley__communities_list_view {
      overflow-y: auto;
      padding: 20px;
      display: flex;
      margin-top: 3rem;
      gap: 2rem;
    }
  }

  .foodvalley {
    @media(max-width: $screen-xl) {
      .foodvalley__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
      }

      .foodvalley__communities_list_view {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
      }
    }

    @media(max-width: $screen-lg) {
      .foodvalley__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        margin-top: -100px;
        align-items: center;

        .foodvalley__card {
          width: 100% !important;
          margin: 20px;
          max-width: 100% !important;
        }
      }

      .foodvalley_events {
        display: flex;
        flex-direction: column;
        padding: 3em 2em;

        .foodvalley_last_event {
          display: flex;
          flex-direction: column;

          .foodvalley_card_info .foodvalley_description p {
            width: 100%;
          }
        }

        .foodvalley_image {
          margin-top: 20px;
        }

        .static-home-foodleap_information {
          p {
            width: 100%;

          }
        }
      }

      .foodvalley__communities_list {
        width: 300px;
      }

      .foodvalley__communities_list_view {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
      }

    }
  }

  @media(max-width: $screen-md-max) {
    .foodvalley__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      align-items: normal !important;

      .foodvalley__card {
        width: 100% !important;
        margin: 20px;
      }
    }

    .foodvalley_events {
      display: flex;
      flex-direction: column;
      padding: 3em 2em;

      .foodvalley_last_event {
        display: flex;
        flex-direction: column;

        .foodvalley_card_info .foodvalley_description p {
          width: 100%;
        }
      }

      .foodvalley_image {
        margin-top: 20px;
      }

      .static-home-foodleap_information {
        p {
          width: 100%;

        }
      }
    }

    .foodvalley__communities_list {
      width: 200px;
    }

    .foodvalley__communities_list_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }
  }

  .spotting-areas-highlighted__section__container {
    display: flex;
    flex-direction: row;

    padding-top: 50px;
    padding-bottom: 50px;
  }

  .spotting-areas-highlighted__section {
    position: relative;

    .spotting-areas-highlighted__background {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      width: 100vw;
      height: 100%;
      background-color: var(--primary-extra-lightest); /* Vervang dit door jouw gewenste kleurcode */
      z-index: -1;
    }

    .spotting-areas-highlighted {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--primary-extra-lightest);
      position: relative;
      padding-bottom: 50px;

      .highlight-spotting-area-button {
        margin-left: 0;
      }

      :deep(ul) {
        list-style-type: disc !important;
        margin-left: 15px;
      }

      .highlighted_spotting-area__content {

        :deep(.button__label) {
          padding-left: 15px !important;
        }

        &.image {
          padding-top: 50px;
        }

        h1 {
          margin-bottom: 12px;
        }

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 12;
          position: relative;
          text-overflow: ellipsis;


          max-height: 300px;
          list-style-type: disc;
        }
      }
    }
  }
</style>
