import objectPath from 'object-path'
import { getTranslationsForTaxonomyValue } from '../store/modules/taxonomies'
import { DEFAULT_LOCALE } from '../store/modules/localization'
import { SiteTemplate } from '../store/modules/config.js'
import moment from 'moment'

const taxonomyTranslations = {}
export default {
  computed: {
    productLabel () {
      return this.localizedDisplayProperty(this.$store.state.config, 'productAlias') || this.$t('product_default_value')
    },
    productsLabel () {
      return this.localizedDisplayProperty(this.$store.state.config, 'productsAlias') || this.$t('products_default_value')
    },
    challengeLabel () {
      return this.localizedDisplayProperty(this.$store.state.config, 'challengeAlias') || this.$t('challenge_default_value')
    },
    challengesLabel () {
      return this.localizedDisplayProperty(this.$store.state.config, 'challengesAlias') || this.$t('challenges_default_value')
    },
    spottingAreaLabel () {
      // resolve instance where introjsMixin was destroyed before rendering with $t
      const alias = this.localizedDisplayProperty(this.$store.state.config, 'spottingAreaAlias')
      let defaultValue = 'Spotting Area'
      if (this.$t) {
        defaultValue = this.$t('spotting_area_default_value')
      }
      return alias || defaultValue
    },
    spottingAreasLabel () {
      // resolve instance where introjsMixin was destroyed before rendering with $t
      const alias = this.localizedDisplayProperty(this.$store.state.config, 'spottingAreasAlias')
      let defaultValue = 'Spotting Areas'
      if (this.$t) {
        defaultValue = this.$t('spotting_areas_default_value')
      }
      return alias || defaultValue
    },
    announcementLabel () {
      const alias = this.localizedDisplayProperty(this.$store.state.config, 'announcementAlias')
      let defaultValue = 'Announcement'
      if (this.$t) {
        defaultValue = this.$t('announcement_default_value')
      }
      return alias || defaultValue
    },
    announcementsLabel () {
      const alias = this.localizedDisplayProperty(this.$store.state.config, 'announcementsAlias')
      let defaultValue = 'Announcements'
      if (this.$t) {
        defaultValue = this.$t('announcements_default_value')
      }
      return alias || defaultValue
    },
    announcementsAndEventsLabel () {
      const aliasAnnouncements = this.localizedDisplayProperty(this.$store.state.config, 'announcementsAlias')
      const aliasEvents = this.localizedDisplayProperty(this.$store.state.config, 'eventsAlias')

      let defaultValueAnnouncements = 'Announcements'
      if (this.$t) {
        defaultValueAnnouncements = this.$t('announcements_default_value')
      }

      let defaultValueEvents = 'Events'
      if (this.$t) {
        defaultValueEvents = this.$t('events_default_value')
      }

      const labelAnnouncements = aliasAnnouncements || defaultValueAnnouncements
      const labelEvents = aliasEvents || defaultValueEvents

      return labelAnnouncements + ' & ' + labelEvents
    },
    announcementAndEventLabel () {
      const aliasAnnouncement = this.localizedDisplayProperty(this.$store.state.config, 'announcementAlias')
      const aliasEvent = this.localizedDisplayProperty(this.$store.state.config, 'eventAlias')

      let defaultValueAnnouncement = 'Announcement'
      if (this.$t) {
        defaultValueAnnouncement = this.$t('announcement_default_value')
      }

      let defaultValueEvent = 'Event'
      if (this.$t) {
        defaultValueEvent = this.$t('event_default_value')
      }

      const labelAnnouncement = aliasAnnouncement || defaultValueAnnouncement
      const labelEvent = aliasEvent || defaultValueEvent

      return labelAnnouncement + ' & ' + labelEvent
    },
    knowledgeBaseLabel () {
      return this.localizedDisplayProperty(this.$store.state.config, 'knowledgeBaseAlias') || this.$t('knowledge_base_default_value')
    },
    postLabel () {
      return this.$t('post_default_value')
    },
    postsLabel () {
      return this.$t('posts_default_value')
    },
    eventLabel () {
      return this.localizedDisplayProperty(this.$store.state.config, 'eventAlias') || this.$t('event_default_value')
    },
    eventsLabel () {
      return this.localizedDisplayProperty(this.$store.state.config, 'eventsAlias') || this.$t('events_default_value')
    },
    locale () {
      return this.$store.getters.activeLocale
    },
    maybeLocale () {
      return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate) ? this.locale : DEFAULT_LOCALE
    },
  },
  methods: {
    maybeTranslate (key) {
      return this.$t(key, { lang: this.maybeLocale })
    },
    localeEnabled (locale) {
      return this.$store.state.localization.enabledLocales.includes(locale)
    },
    localizedDisplayProperty (entity, property) {
      if (!entity) {
        // This could be because the entity hasn't been properly loaded yet, but the function is called anyway
        return
      }

      let result = entity[property]

      if (entity.translations && entity.translations[this.locale] && entity.translations[this.locale][property]) {
        result = entity.translations[this.locale][property]
      }

      return result
    },
    getLabelForTaxonomyValue (taxonomy, value, locale) {
      const valueKey = 'taxonomy_id_' + value

      if (!value) {
        return ''
      }

      if (!locale) {
        locale = this.locale
      }

      // Make sure the locale is enabled
      if (!this.localeEnabled(locale)) {
        locale = DEFAULT_LOCALE
      }

      if (taxonomy === 'subIndustries') {
        taxonomy = 'industries'
      }

      if (taxonomy === 'maturity') {
        return value
      }

      if (taxonomyTranslations[taxonomy] && taxonomyTranslations[taxonomy][valueKey] && taxonomyTranslations[taxonomy][valueKey][locale] && taxonomyTranslations[taxonomy][valueKey][locale].name) {
        return taxonomyTranslations[taxonomy][valueKey][locale].name
      }

      // Fetch the translations for the taxonomy and values, fetch the right one and store it in the cache
      const translations = getTranslationsForTaxonomyValue(taxonomy, value)

      if (!translations) {
        return ''
      }

      if (!taxonomyTranslations[taxonomy]) {
        taxonomyTranslations[taxonomy] = {}
      }

      if (!taxonomyTranslations[taxonomy][valueKey]) {
        taxonomyTranslations[taxonomy][valueKey] = {}
      }

      taxonomyTranslations[taxonomy][valueKey] = translations

      if (taxonomyTranslations[taxonomy][valueKey][locale] && taxonomyTranslations[taxonomy][valueKey][locale].name) {
        return taxonomyTranslations[taxonomy][valueKey][locale].name
      } else if (taxonomyTranslations[taxonomy][valueKey][DEFAULT_LOCALE] && taxonomyTranslations[taxonomy][valueKey][DEFAULT_LOCALE].name) {
        return taxonomyTranslations[taxonomy][valueKey][DEFAULT_LOCALE].name
      }

      return ''
    },
    getTranslationForRelationName (relationName) {
      if (this.maybeLocale === DEFAULT_LOCALE) {
        return relationName
      }

      const relationTranslationKey = `relation_${relationName.split(' ').join('_')}`
      const translatedRelation = this.$t(relationTranslationKey)
      return `${translatedRelation}` || `${relationName}`
    },
  },
}

export function localizedEditProperty (localePath, entityPath, property) {
  return {
    get () {
      const entity = objectPath.get(this, entityPath, this[entityPath])

      if (!entity) {
        return
      }

      const locale = this[localePath]

      if (!locale || locale === DEFAULT_LOCALE) {
        return entity[property]
      }

      return objectPath.get(entity, `translations.${locale}.${property}`)
    },
    set (value) {
      const entity = objectPath.get(this, entityPath, this[entityPath])

      if (!entity) {
        return
      }

      const locale = this[localePath]

      if (!locale || locale === DEFAULT_LOCALE) {
        entity[property] = value
      } else {
        if (!entity.translations) {
          entity.translations = {}
        }

        if (!entity.translations[locale]) {
          entity.translations.locale = {}
        }

        entity.translations[locale][property] = value
      }
    },
  }
}

export function localizedEditPropertyValues (entityPath, property) {
  return {
    get () {
      const entity = objectPath.get(this, entityPath)

      if (!entity) {
        return
      }

      const actorTranslations = entity.translations || {}
      const values = { [DEFAULT_LOCALE]: entity[property] }

      for (const language in actorTranslations) {
        if (actorTranslations[property]) {
          values[language] = actorTranslations[property]
        }
      }

      return values
    },
    set () {
      throw new Error('Can\'t modify this translations object directly')
    },
  }
}
