<template>
  <router-link
      :to="link"
      :target="newWindow ? '_blank' : '_self'"
      class="simplified-connection"
      :key="resizeKey"
  >
    <div class="event-card" ref="challengeContainer">
      <div class="event-card__title">
        <h3 class="" @click="visitEvent('titleClickDetail')">
          {{ localizedDisplayProperty(event, 'title') }}</h3>
      </div>
      <div class="event-card__date">
        <p v-html="getFormattedEventDate(event)"></p>
      </div>
      <div class="event-card__description">
        <p v-html="localizedDisplayProperty(event, 'shortDescription')"></p>
      </div>
      <div v-if="getImageForAnnouncement(event)" class="event-card__image--wrapper"
           :class="{'show-event-image': showImage}">
        <div class="event-card__image"
             :style="{'background-image': `url(${getImage()})`, height: fixedImageSize}">
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { getImageForAnnouncement, getImageForCommunity } from '../../util/helpers.js'
  import { defineComponent } from 'vue'
  import { getFormattedEventDate } from '@/util/date'

  export default defineComponent({
  name: 'event-card',
  props: {
    event: Object,
    newWindow: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    automaticallyCalculateImageHeight: {
      type: Boolean,
      default: false
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    isCommunityDetail: {
      type: Boolean,
      default: false,
    },
    community: Object,
  },
  data() {
    return {
      fixedImageSize: 0,
      resizeKey: 0,
    }
  },
  mixins: [TranslationsMixin],
  methods: {
    getFormattedEventDate,
    getImageForAnnouncement,
    getImage() {
      if (!this.event || !this.event.image || this.event.image === '') {
        if (this.isCommunityDetail && this.community && this.community.id && this.community.image) {
          return getImageForCommunity(this.community)
        }
        return this.getImageForAnnouncement(this.event)
      }

      return this.getImageForAnnouncement(this.event)
    },
    visitEvent(eventName) {
      trackHeapEvent('eventCard' + eventName)
      this.$router.push('/announcements/' + this.event.id)
    },
    calculateImageHeight() {
      if (this.showImage) {
        this.fixedImageSize = '140px'
      }

      if (!this.automaticallyCalculateImageHeight) {
        return
      }

      if (this.$refs.challengeContainer) {
        this.fixedImageSize = `calc(0.5625 * ${getComputedStyle(this.$refs.challengeContainer).width} + 12px)`
      } else {
        this.fixedImageSize = 'auto'
      }
    },
  },
  mounted() {
    this.calculateImageHeight()
    window.addEventListener('resize', this.calculateImageHeight)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateImageHeight)
  },
})
</script>

<style scoped lang="scss">
@import "../../../scss/variables.scss";

.event-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    .event-card__image {
      cursor: pointer;
      transform: scale(1.2);
    }
  }

  .show-event-image {

  }
}

.event-simplified-image-card {
  height: 100%;
  transition: all .3s ease-in-out;
  background-color: #EBEBEC;
  border-radius: $default-border-radius;

  &.has-background-white {
    background: white;

    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  }

  &:hover {
    background-color: var(--primary-extra-lightest);
  }
}

.event-card__image--wrapper {
  overflow: hidden;
}

.event-card__image {
  background-size: cover;
  flex-grow: 1;
  border-radius: 0 0 10px 10px;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transform: scale(1);
}

.event-card__title {
  padding: 0 10px;
  margin-top: 10px;

  h3 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.event-card__date {
  margin-top: 5px;
  color: $color-text-grey;
  padding: 0 10px;
  display: flex;
  margin-bottom: 20px;
  height: 36px;
}

.event-card__description {
  margin-top: -5px;
  margin-bottom: 10px;
  color: $color-text-grey;
  padding: 0 10px;

  p {
    $nrOfLines: 6;
    $lineHeight: 18px;
    overflow: hidden;
    -webkit-line-clamp: $nrOfLines;
    height: $lineHeight * $nrOfLines;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: $lineHeight;

  }
}
</style>
