<template>
  <modal
      :id="modalId"
      :componenyKey="componentKey"
      class="modal__has-header-tabs manage-announcement-modal"
      ref="modal"
      :title="action === 'edit' ? modalTitle(type) : ''"
      :showTitleTabs="action === 'create'"
      @close="close"
      is-simplified-modal
  >
    <!-- Modal header tabs -->
    <template v-slot:title v-if="action === 'create'">
      <div class="modal__header-tabs">
        <div :class="{'tab-title': true}" @click="changeBody('announcement')">
          <h2 class="title" v-html="modalTitle('announcement')"></h2>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div>
        <div class="announcement-edit__title-container">
          <div class="mandatory-message">
            <i>{{ $t('all_fields_with_asterisk_are_mandatory') }}</i>
          </div>

          <ds-input
              is-simplified
              v-model="announcement.title"
              :placeholder="$t('announcement_edit_title', {announcement: getModalLabel()}) + ' *'"
              :minLength="2"
              :maxLength="100"
              :autofocus="true"
              ref="titleInput"
          />
        </div>

        <div class="announcement-edit__content-container">
          <div v-if="errors && errors.length > 0" class="form-group__error">
            <div v-for="message in errors">
              <span>{{ message }}</span>
            </div>
          </div>

          <div v-if="type === 'announcement'">
            <div v-if="announcementCategoryOptions.length > 0" style="margin-bottom: 1rem;">
              <dropdown
                  :search="true"
                  is-simplified
                  v-model="announcement.announcement_categories"
                  multiple
                  :options="announcementCategoryOptions"
                  :placeholder="$t('edit_announcement_category_placeholder', { announcement: 'announcement'})"
              />
              <br/>
            </div>

            <rich-text-area
                v-model="announcement.body"
                :minLength="10"
                :allowDragAndDrop="true"
                :currentContentLength="announcementBodyWithoutHtml.length"
                :custom-placeholder="$t('announcement_description_placeholder') + '*'"
                :also-allow-user-mentions="false"
                class="announcement-detail-body-style" is-simplified/>

            <div v-if="announcement.file">
              <br/>
              <form-group :label="$t('add_announcement_shared_resource')">
                <announcement-or-resource-card
                    :data="announcement.file"
                    :preview-mode="true"
                />
              </form-group>
            </div>

            <br>
            <autocomplete-tag-input
                is-simplified
                :options="tagOptions"
                :addOnlyFromAutocomplete="false"
                :tags="announcement.tags"
                @tagChanged="handleTagChanged"
                @input:raw="updateTags"
                :minInputLength="1"
            />
            <br/>

            <div v-if="displayCommunitiesDropdown" style="margin-bottom: 1rem;">
              <autocomplete-dropdown
                  is-simplified
                  :model-value="announcement.communities"
                  :options="communityOptions"
                  :do-search="handleCommunityChanged"
                  @update:modelValue="updateCommunities"
                  :multiple="true"
                  :allowClear="true"
                  placeholder="Link to communities"
                  :doSearchOnCreated="true"
              />
            </div>

            <image-input
                v-model="announcement.originalImage"
                @update:modelValue="announcement.image = $event" width="510"
                height="131.443298969"
                :placeholder="$t('announcement_image_placeholder')"
                :is-simplified="true"
                v-if="!announcement.file"
            />
            <!--131.443298969 for 970x250 aspect ratio-->
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
            variant="rounded"
            @click="submit"
            :label="action === 'edit' ? $t('announcement_save') : $t('announcement_post', {announcement: getModalLabel()})"
            size="extra-small"
            :disabled="!canSubmit"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from './Modal.vue'
import MODAL_IDS from '../../constants/modal-ids.js'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import RichTextArea from '../TextArea/RichTextArea.vue'
import Dropdown from '../Dropdown/Dropdown.vue'
import Datepicker from '../Form/Datepicker.vue'
import DateTimepicker from '../Form/DateTimepicker.vue'
import ImageInput from '../Form/ImageInput.vue'
import AutocompleteTagInput from '../Form/AutocompleteTagInput.vue'
import CountryDropdown from '../Dropdown/CountryDropdown.vue'
import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'
import AnnouncementOrResourceCard from '../Simplified/AnnouncementOrResourceCard.vue'

import TagsMixin from '../../util/TagsMixin.js'
import { Notifications } from '../../api/notifications.js'
import { validAnnouncementFilters } from '../../store/modules/notifications.js'
import { Communities } from '../../api/communities'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import _isEmpty from 'lodash/isEmpty'
import FiltersMixin from '../../util/FiltersMixin'

export default {
  data () {
    return {
      modalId: MODAL_IDS.MANAGE_ANNOUNCEMENT,
      componentKey: 0,
      announcement: {
        actor_id: null,
        tags: [],
        announcement_categories: [],
        communities: [],
        title: '',
        address_line: '',
        action_url: null,
        body: '<div></div>',
        reach: 'public',
        is_event: false,
        start_date: null,
        end_date: null,
        file: null,
        context: {
          address: {
            street: '',
            number: '',
            zip: '',
            city: '',
            country: '',
            country_code: '',
          },
          location: {
            lat: '',
            long: '',
          },
        },
      },
      minTitleLength: 2,
      minBodyLength: 10,
      // type: 'announcement',
      // For some odd reason, using the announcement.context.address as the v-model, changes the vuex state, while changing other properties of the same object does not
      // Instead of further debugging, we went for this solution, using a dedicated address object, hydrated with data of the existing announcement if applicable
      newAddress: {
        street: '',
        number: '',
        zip: '',
        city: '',
        country: '',
        country_code: '',
      },
      errors: {},
      saving: false,
      communityOptions: [],
      allCommunityOptions: [],
    }
  },
  computed: {
    displayCommunitiesDropdown () {
      return this.$store.getters.hasAccessToCommunities && this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities && this.allCommunityOptions.length > 0 && (this.$store.getters.isMember || this.$store.getters.isPortfolioMember)
    },
    canSubmit () {
      let canSubmit = false

      canSubmit = (this.announcement.title && this.announcement.title.length > this.minTitleLength && this.announcementBodyWithoutHtml.length >= this.minBodyLength)

      return canSubmit && !this.saving
    },
    announcementBodyWithoutHtml () {
      return (this.announcement.body && this.announcement.body.replace(/<\/?[^>]+(>|$)/g, '')) || ''
    },
    modalContext () {
      return this.$store.state.ui.modalContext
    },
    action () {
      return this.announcement.id ? 'edit' : 'create'
    },
    isDeveloper () {
      return this.$store.getters.isDeveloper
    },
    config () {
      return this.$store.state.config
    },
    type () {
      if (!this.announcement.is_event) {
        return 'announcement'
      }

      return 'event'
    },
    activeCommunityId () {
      if (this.$route.name !== 'community-detail') {
        return
      }
      return this.$route.params.communityId
    },
  },
  methods: {
    selectDefaultForSingleCommunityMembers () {
      // If there's a new announcement to be made and the user is in the context of a community, set the community value
      if (!this.announcement.id && this.activeCommunityId) {
        const community = this.allCommunityOptions.find(community => community.id == this.activeCommunityId)

        if (!community) {
          return
        }

        this.announcement.communities = [{
          label: community.name,
          value: community.id,
        }]
      }
    },
    getModalLabel () {
      let label = this.$t('announcement_default_value')

      if (this.type === 'event') {
        label = this.$t('event_default_value')
      }

      return label
    },
    modalTitle (type) {
      let label = this.$t('announcement_default_value')

      if (type === 'event') {
        label = this.$t('event_default_value')
      }

      return this.action === 'edit' ? this.$t('edit_announcement_panel_title', { announcement: label }) : this.$t('add_announcement_panel_title', { announcement: label })
    },
    handleTagChanged (tag) {
      // Update the options according to the given tag
      this.updateTagOptions(tag)
    },
    updateTags (tags) {
      this.announcement.tags = tags.map(item => {
        if (item.optionValue) {
          return item.optionValue
        }

        return {
          label: item.text,
          value: item.text,
          text: item.text,
        }
      })
    },
    handleCommunityChanged (community) {
      return Communities
          .get('', { query: community })
          .then(response => {
            return this.updateCommunityOptions(response)
          })
    },
    handleCountryChange (country) {
      this.newAddress.country_code = country.code
      this.newAddress.country = country.name
    },
    updateCommunities (communities) {
      this.announcement.communities = communities.map(item => {
        if (item.optionValue) {
          return item.optionValue
        }

        return {
          label: item.label,
          value: item.value,
        }
      })
    },
    updateCommunityOptions (communityOptions) {
      return communityOptions
          .map(option => {
            return {
              label: option.name,
              text: option.name,
              value: option.id,
            }
          })
    },
    validateForm () {
      this.errors = {}

      if (this.announcement.title.length < this.minTitleLength) {
        this.errors.title = this.$t('announcement_error_title_requires_at_least_chars', { characters: this.minTitleLength })
      }
      if (this.announcementBodyWithoutHtml.length < this.minBodyLength) {
        this.errors.body = this.$t('announcement_error_body_requires_at_least_chars', { characters: this.minBodyLength })
      }
    },
    submit () {
      this.componentKey++
      this.validateForm()
      if (!_isEmpty(this.errors)) {
        // Scroll to top within modal to see the error messages
        $('#' + this.modalId).scrollTop(0)

        return
      }

      this.saving = true

      if (this.isDeveloper) {
        this.announcement.ecosystem_id = this.config.id
      }

      // Transform the object to contain the file id, instead of the full file object, if applicable
      var announcementObject = { ...this.announcement }
      announcementObject.file_id = announcementObject.file && announcementObject.file.sql_media_id

      delete announcementObject.file

      Notifications
          .post(announcementObject)
          .then(() => {
            setTimeout(() => {
              if (this.action == 'edit') {
                this.$bus.emit('announcementUpdated', {
                  id: this.announcement.id,
                  filteredTypes: validAnnouncementFilters,
                })
              } else {
                this.$bus.emit('announcementCreated')
              }
            }, 1000)
          })
          .catch(errors => {
            this.saving = false
            this.errors = Object.keys(errors)
                .filter(key => key !== 'statusCode')
                .map(key => {
                  if (typeof errors[key] === 'string' || errors[key] instanceof String) {
                    return errors[key]
                  }

                  return errors[key][0]
                })
          })
          .finally(() => {
            if (Object.keys(this.errors).length === 0) {
              setTimeout(() => {
                this.success()
              }, 2000)
            }
          })
    },
    success () {
      this.saving = false

      let label = this.$t('announcement_default_value')

      if (this.type === 'event') {
        label = this.$t('event_default_value')
      }

      let labelSuccess = this.$t('announcement_create_success_being_curated', { announcement: label })

      if (this.$store.getters.isOwner || this.$store.getters.isDeveloper || this.$store.getters.isTeamMember || !this.config.publisher.shouldCurateContentToAnnouncements) {
        labelSuccess = this.$t('announcement_create_success', { announcement: label })
      }

      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        message: this.action == 'edit' ? this.$t('announcement_edit_success', { announcement: label }) : labelSuccess,
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SUCCESS)
    },
    close () {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
      this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
      this.$emit('close')
    },
    changeBody (type) {
      this.errors = {}
      this.announcement.is_event = (type === 'event')

      this.$refs.titleInput.$refs.input.focus()
    },
  },
  async mounted () {

    if (this.modalContext && this.modalContext.announcement) {
      this.announcement = { ...this.announcement, ...this.modalContext.announcement }

      this.announcement.originalImage = '/notifications/' + this.announcement.id + '/image'

      this.newAddress = (this.announcement.context && this.announcement.context.address) ? { ...this.announcement.context.address } : {
        street: '',
        number: '',
        zip: '',
        city: '',
        country: '',
        country_code: '',
      }
    }

    if (this.$store.getters.hasAccessToCommunities) {
      try {
        this.allCommunityOptions = await Communities.get('', { query: '', isAnnouncement: true })
        this.selectDefaultForSingleCommunityMembers()
      } catch (error) {
        console.log(error)
      }
    }

    this.$refs.titleInput.$refs.input.focus()
  },
  components: {
    Modal,
    RichTextArea,
    ImageInput,
    Datepicker,
    Dropdown,
    DateTimepicker,
    AutocompleteTagInput,
    CountryDropdown,
    AutocompleteDropdown,
    AnnouncementOrResourceCard,
  },
  mixins: [TranslationsMixin, TagsMixin, FiltersMixin],
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables.scss";

.announcement-edit__title-container {
  background: var(--primary-community-extra-lightest);
  padding: 20px;

  :deep(input, .time-picker, .datepicker, .multiselect.multiselect--datascouts .multiselect__tags) {
    border: 0;

    &::placeholder {
      color: var(--primary-community);
    }
  }
}

.announcement-edit__content-container {
  padding: 20px 20px 17px 20px;

  :deep(input, .datepicker, .time-picker) {
    border: 1;

    &::placeholder {
      color: var(--primary-community);
    }
  }

  .multiselect.multiselect--datascouts {
    margin-right: 0px !important;
  }

  .form-group__error {
    font-size: 14px;
    margin-bottom: 7px;
  }
}

.modal__footer {
  width: 100%;

  .button {
    width: 100%;
    border-radius: $default-border-radius-narrow;
  }
}

.modal__header-tabs {
  display: flex;
  justify-content: flex-start;
  background: white;
  flex: 1;
  // max-width: 90%;

  > .tab-title {
    cursor: pointer;
    flex: 1;

    &.active {
      background: var(--primary-community-extra-lightest) !important;
    }

    &:last-child {
      margin-right: 58px;
    }

    > .title {
      padding: 20px !important;
      font-size: 14px !important;
    }
  }
}

.d-flex {
  display: flex;
}

.form-group {
  margin-bottom: 0px;
}
</style>
