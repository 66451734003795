<template>
  <div
      v-if="announcementCopy"
      :class="{'announcement-card': true, 'full-card-clickable': fullCardClickable}"
      :key="'announcement-card_' + announcement.id"
  >
    <div
        class="announcement-card__actions"
        v-if="availableActions.length > 0"
    >
      <div
          v-for="(action, index) in availableActions"
          :key="'action' + index + componentKey"
      >
        <template
            v-if="showAvailableAction(action.type)"
        >
          <template v-if="ANNOUNCEMENT_SHARE_ACTION_TYPES.includes(action.type)">
            <div class="announcement-card__action">
              <share-network
                  :url="action.type === ANNOUNCEMENT_ACTION_TYPES.SHARE_TWITTER ? announcementShortUrl : announcementFullUrl"
                  :title="announcement.title"
                  :network="getNetworkForActionType(action.type)"
                  description=""
                  :quote="action.type === ANNOUNCEMENT_ACTION_TYPES.SHARE_FACEBOOK ? announcement.title : ''"
                  :hashtags="announcementTags"
                  :twitter-user="action.type === ANNOUNCEMENT_ACTION_TYPES.SHARE_TWITTER ? 'datascouts' : ''"
                  style="cursor: pointer;"
              >
                <icon
                    :name="`${getNetworkForActionType(action.type)}-logo`"
                    size="22"
                />
              </share-network>
            </div>
          </template>
          <div
              @click="toggleAction(action)"
              v-tooltip.top="action.tooltip"
              :class="{ 'announcement-card__action': true, 'disable-action': actionInProgress === action.type }"
          >
            <div
                v-if="action.type === ANNOUNCEMENT_ACTION_TYPES.LIKE && announcementCopy && announcementCopy.user_like">
              <icon name="thumbs-up" v-if="announcementCopy.user_like" size="14"/>
              <icon name="thumbs-up-outline" size="14" v-else/>
            </div>
            <div v-else>
              <icon
                  v-if="action.icon"
                  :name="getNameForIcon(action.icon)"
                  size="18"/>
            </div>
            <div :class="getCountPositionForAction(action)">{{
                getCountForAction(action)
              }}
            </div>
          </div>
        </template>
      </div>
      <div
          class="announcement-card__action announcement-card__action--show-more"
          v-if="!showMoreAvailableActions && canViewMoreActions(announcement)"
          v-tooltip.top="'More actions'"
          @click="displayAllAvailableActions"
      >
        <icon name="ellipsis-h"/>
      </div>
    </div>
    <div class="announcement-card__content">
      <!-- card header -->
      <div class="announcement-header">
        <div
            v-if="!postedFromActor"
            class="announcement-header-user-container"
        >
          <!-- start/open conversation when clicking the user's avatar -->
          <span
              :class="{'clickable': canStartConversation(announcement.user)}"
              @click="startConversation(announcement.user, 'announcementHeader.startConversation')"
          >
            <div
                :style="backgroundImageStyle"
                :class="(!!backgroundImageStyle) ? 'has-image' : 'has-no-image'"
                v-tooltip.right="canStartConversation(announcement.user) ? 'Start conversation with ' + announcement.user.name : (announcement.user.id === userId ? 'You': '')"
                class="announcement-header-img"
            >
              <span v-if="!backgroundImageStyle && announcement.actor_name">{{
                  announcement.actor_name.slice(0, 1)
                }}</span>
            </div>
          </span>
          <!-- display user name (+ optional: actor(s))  -->
          <div class="announcement-header-user-inner-container">
            <div class="announcement-header-user">
              <!-- show actor on top if created from actor page, else show user on top (DS-1849)-->
              <div
                  :class="{'announcement-header-user__user-name': true, 'announcement-has-tags': displayAnnouncementTags.length}"
              >
                {{ announcement.user.name }}
              </div>
              <div
                  v-if="announcement.user && announcement.user.claims && announcement.user.claims.length > 0"
                  :class="{'announcement-header-user__actor-name': true, 'announcement-has-tags': displayAnnouncementTags.length}"
              >
                <template v-for="(actor, actorIndex) in announcement.user.claims">
                  <router-link :to="'/actors/' + actor.id">
                    {{ actor.name }}
                  </router-link>
                  <span v-if="actorIndex !== announcement.user.claims.length - 1">, </span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div
            v-if="postedFromActor"
            class="announcement-header-user-container"
        >
          <!-- show actor info, no user info -->
          <span>
            <div
                :style="backgroundImageStyle"
                :class="(!!backgroundImageStyle) ? 'has-image' : 'has-no-image'"
                class="announcement-header-img"
            >
              <span v-if="!backgroundImageStyle && announcement.actor_name">{{
                  announcement.actor_name.slice(0, 1)
                }}</span>
            </div>
          </span>
          <!-- display actor name -->
          <div class="announcement-header-user-inner-container">
            <div class="announcement-header-user">
              <div
                  :class="{'announcement-header-user__user-name': true, 'announcement-has-tags': displayAnnouncementTags.length}"
              >
                {{ announcement.user.name }}
              </div>
              <div
                  v-if="announcement.actor_name && announcement.actor_id"
                  :class="{'announcement-header-user__actor-name': true, 'announcement-has-tags': displayAnnouncementTags.length}"
              >
                <router-link :to="'/actors/' + announcement.actor_id">
                  {{ announcement.actor_name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Only display the tag "event" in the announcement header -->
        <!-- note: for the mobile version, we display the tags above  the description -->
        <template v-if="announcement.is_event">
          <div
              class="announcement-header-tags"
              ref="tagsContainer"
          >
            <div class="content__meta">
              <ul>
                <li
                    v-for="(tag, index) in eventTags"
                    :key="'announcement-tags--' + announcement.id + '-' + tag.value + index"
                >
                  <button
                      :class="previewMode ? 'tag tag--preview' : tag.isActive ? 'tag tag--active' : 'tag'"
                      @click.prevent="onClickTag($event, tag)"
                  >
                    {{ tag.label }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template v-if="isCommunityEcosystem">
          <div class="announcement-header-actions">
            <div
                v-for="(action, index) in availableActionsForCommunityEcosystem"
                :key="'action' + index + componentKey"
                class="announcement-header-actions--item"
            >
              <div
                  @click="toggleAction(action)"
                  v-tooltip.top="action.tooltip"
                  :class="{ 'announcement-card__action': true, 'disable-action': actionInProgress === action.type }"
              >
                <icon
                    v-if="action.icon"
                    :name="getNameForIcon(action.icon)"
                ></icon>
                <div :class="getCountPositionForAction(action)">{{
                    getCountForAction(action)
                  }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
            class="announcement-header-created"
            :style="isCommunityEcosystem ? `color: #323441;`: `color: var(--primary-community);`"
        >
          {{ $t('announcement_posted_on') }} {{ createdDateString }}
        </div>
      </div>

      <!-- card body -->
      <div
          :class="{'announcement-content-container' : true, 'has-all-actions-shown': showMoreAvailableActions, 'full-card-clickable': fullCardClickable}"
          @click="fullCardClickable ? onClickReadMore() : false"
      >
        <!-- content: title, date -->
        <div
            :class="{'announcement-content': true, 'enable-click-read-more': !isDetail}"
            @click="!isDetail ? onClickReadMore() : false"
        >
          <div class="announcement-content-title-container">
            <div class="announcement-content-title">
              {{ announcement.title }}
            </div>
          </div>
          <div v-if="announcement.is_event" class="announcement-event-times">
            <div v-if="!isDetail" class="svg-icon svg-icon--18" style="margin-right: 10px;">
              <icon name="calendar-plus" size="18" :style="{fill: ecosystemColor}"/>
            </div>
            <div v-html="getFormattedEventDate(announcement)"></div>
          </div>
          <div
              v-if="announcement.is_event && isDetail && isLoggedIn"
              class="announcement-event-times"
              style="text-decoration: underline; cursor: pointer;"
              @click="showExternalCalendarModal(announcement)"
          >
            <icon name="calendar-plus"
                  size="18"
                  :style="{fill: ecosystemColor}"/>
            <div style="margin-left: 10px;">{{
                announcement.user_event_calendar ? $t('added_to_your_calendar') : $t('add_to_your_calendar')
              }}
            </div>
          </div>
          <div
              v-if="announcement.is_event && announcement.address_line"
              class="announcement-content-address"
          >
            <icon
                name="map-marker"
                size="18"
            />
            <p
                @click="announcement.image ? showAddressModal(announcement) : ''"
                :class="{'clickable-address': announcement.image}"
            >
              {{ announcement.address_line }}
            </p>
          </div>
          <template
              v-if="isDetail"
              class="announcement-content-body"
          >
            <rich-body-content :html="announcement.body"/>

            <template
                v-if="!announcement.is_event && announcement.action_url && announcement.action_text && announcement.action_text.length"
            >
              <p><a
                  :href="announcement.action_url"
                  target="_blank"
              >{{ announcement.action_text }}</a></p>
            </template>

            <template v-if="announcement.file && announcement.file.title">
              <announcement-or-resource-card
                  :data="announcement.file"
                  :preview-mode="!isOwner && !isTeamMember"
                  :allow-actions="false"
                  style="margin-top: 1rem;"
              />
            </template>
          </template>

          <template v-else>
            <div class="announcement-content-body">{{ announcement.shortDescription }}
              <span v-if="showReadMoreButton(announcement)">&hellip;
                <button
                    type="button"
                    style="display: contents;"
                    class="a subtle-button"
                    @click="onClickReadMore"
                >{{ $t('headline_read_more_text') }}</button>
              </span>
            </div>
          </template>
        </div>

        <!-- content: tags (not clickable to the detail) -->
        <div
            :class="{'announcement-content': true, 'enable-click-read-more': false}"
            v-if="
            showTags &&
            (displayAnnouncementTags(false) && displayAnnouncementTags(false).length) ||
            (displayAnnouncementCommunityTags && displayAnnouncementCommunityTags.length)"
        >
          <!-- tags (without communities)  -->
          <template v-if="displayAnnouncementTags(false).length > 0">
            <div
                class="announcement-content-tags"
                ref="tagsContainer"
            >
              <!-- new way of showing tags, as on KB -->
              <div class="content__meta">
                <ul>
                  <li style="padding: 5px;">
                    <icon name="tags" size="18" :style="{fill: ecosystemColor}"/>
                  </li>
                  <li
                      v-for="(tag, index) in displayAnnouncementTags(false)"
                      :key="'announcement-tags--' + announcement.id + '-' + tag.value + index"
                  >
                    <button
                        :class="previewMode ? 'tag tag--preview' : tag.isActive ? 'tag tag--active' : 'tag'"
                        @click.prevent="onClickTag($event, tag)"
                    >
                      {{ tag.label }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <!-- tags (community) -->
          <template
              v-if="showTags && this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities && displayAnnouncementCommunityTags.length > 0"
          >
            <div
                class="announcement-content-tags"
                ref="tagsContainer"
            >
              <!-- new way of showing tags, as on KB -->
              <div class="content__meta">
                <ul>
                  <li>
                    <icon name="users" size="14"/>
                  </li>
                  <li
                      v-for="(tag, index) in displayAnnouncementCommunityTags"
                      :key="'announcement-tags--' + announcement.id + '-' + tag.value + index"
                  >
                    <button
                        :class="previewMode ? 'tag tag--preview' : tag.isActive ? 'tag tag--active' : 'tag'"
                        @click.prevent="onClickTag($event, tag)"
                    >
                      {{ tag.label }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>

        <!-- content: maps/image -->
        <div
            v-if="showImage && announcement.image"
            :class="{'announcement-content': true, 'enable-click-read-more': !isDetail}"
            @click="!isDetail ? onClickReadMore() : false"
        >
          <div
              class="announcement-content-image"
          >
            <img
                :src="`/notifications/${announcement.id}/image`"
                ref="announcementImage"
            >
          </div>
        </div>
        <div v-else>
          <div
              v-if="announcement.is_event && getLocation()"
              style="position: relative; height: 220px"
              class="announcement-content-map"
          >
            <SimpleMap
                :location="getLocation()"
                :disableMouseWheelZoom="true"
            />
          </div>
        </div>
      </div>

      <div
          v-if="isDetail && announcement.is_event && announcement.action_url"
          :class="{'announcement-content': true, 'enable-click-read-more': false}"
      >
        <div class="announcement-cta">
          <ds-button
              :label="$t('announcement_call_to_action')"
              :href="announcement.action_url"
              variant="rounded"
          />
        </div>
      </div>

      <!-- on detail page: show list of comments -->
      <div
          v-if="isDetail"
          ref="comments"
          class="announcement-comments"
      >
        <div v-if="isLoggedIn">
          <div
              v-if="errors.comment"
              class="announcement-comment"
          >
            <p
                class="form-group__error"
                v-for="error in errors.comment"
            >{{ error }}</p>
          </div>
          <div class="announcement-comment">
            <div
                class="announcement-new-comment__image"
                :style="{backgroundImage: `url(${$store.state.user.profile.photo_url})`}"
            />
            <div
                v-if="newCommentSuccess"
                class="talkbubble success"
            >
              <div class="announcement-comment__text">
                {{ $t('announcement_new_comment_thank_you') }}&nbsp;&nbsp;
                <ds-button
                    size="extra-small"
                    @click="newCommentSuccess = false"
                    :label="$t('announcement_add_new_comment')"
                />
              </div>
            </div>
            <form-group
                v-else
                class="announcement-new-comment_form"
            >
              <div class="talkbubble">
                <rich-text-area
                    class="newcomment__input"
                    :key="'newcomment-' + announcement.id"
                    v-model="newComment"
                    :is-simplified="true"
                    :hide-actions="true"
                    :auto-focus="shouldScrollToComments"
                    :currentContentLength="commentBodyWithoutHtml.length"
                    :also-allow-user-mentions="true"
                    :community-id="activeCommunityId"
                    :custom-placeholder="$t('comment_placeholder')"
                />
              </div>
              <ds-button
                  @click="addComment()"
                  variant="rounded"
                  class="announcement-new-comment__send"
                  :icon="loadingNewComment ? '' : 'paper-plane'"
                  icon-fill-color="white"
                  size="small"
              />
            </form-group>
          </div>
        </div>
        <div
            v-else
            class="announcement-comment"
        >
          <div class="talkbubble">
            <ds-input
                :placeholder="$t('announcement_new_comment_cta')"
                @focus="addComment"
                @pressedEnter="addComment"
                v-model="newComment"
            />
          </div>
          <ds-button
              @click="addComment()"
              variant="rounded"
              class="announcement-new-comment__send"
              :key="commentsIconKey"
              :icon="loadingNewComment ? 'spinner' : 'paper-plane'"
              size="small"
          />

        </div>
        <template v-if="comments && comments.length">
          <div class="announcements-divider">
            {{ $t('announcement_latest_comments') }}
            <div class="hr"/>
          </div>
          <div
              class="announcement-comment"
              v-for="(comment, index) of comments"
              :key="'comment' + index"
          >
            <span
                :class="{'clickable': canStartConversation(comment.user)}"
                @click="startConversation(comment.user, 'announcementHeader.startConversationFromComment')"
            >
              <div
                  class="announcement-new-comment__image"
                  v-tooltip.right="(canStartConversation(comment.user) ? 'Start conversation with ' + comment.user.name : (comment.user.id === userId ? 'You': ''))"
                  :style="{backgroundImage: `url(${comment.user.photo_url})`}"
              />
            </span>
            <div class="talkbubble">
              <div style="display: flex; width: 100%">
                <div class="announcement-comment__user">
                  {{ comment.user.name }}
                </div>
                <div class="announcement-comment__date">
                  {{ comment.created_at }}
                </div>
              </div>
              <div class="announcement-comment__text">
                <rich-body-content
                    :html="comment.comment"
                    style="margin-bottom: unset;"
                ></rich-body-content>
              </div>
            </div>
            <div class="comment-button">
              <ds-button
                  v-if="editable(comment)"
                  @click="deleteComment(comment)"
                  class="button--minimal button--default"
                  icon="trash"
                  size="small"
                  iconSize="18"
              />
            </div>
          </div>
        </template>
      </div>
      <div
          class="announcement-comment"
          v-else-if="enableComment"
          @click="onClickNewComment"
      >
        <div
            class="announcement-new-comment__image"
            :style="{backgroundImage: `url(${$store.state.user.profile.photo_url})`}"
        />
        <div class="talkbubble">
          {{ $t('announcement_new_comment_cta') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getFormattedEventDate } from '../../util/date.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import AnnouncementMixin from '../../util/AnnouncementMixin.js'
  import Tag from '../Tag/Tag.vue'
  import RichBodyContent from '../RichBodyContent/RichBodyContent.vue'
  import RichTextArea from '../../components/TextArea/RichTextArea.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import SimpleMap from '../../components/SimpleMap/SimpleMap.vue'
  import { ANNOUNCEMENT_ACTION_TYPES } from '../../constants/announcement-constants.js'
  import AnnouncementOrResourceCard from './AnnouncementOrResourceCard.vue'
  import moment from 'moment'
  import _isEmpty from 'lodash/isEmpty.js'
  import { trackHeapEvent, trackMatomoEvent } from '../../util/analytics'
  import { actorLogo } from '../../util/actor'
  import { Conversations } from '../../api/conversations'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations'
  import { deleteComment, fetchNotification, likeNotification } from '../../api/notifications.js'
  import { ConversationsMixin } from '../../util/ConversationsMixin'
  import { defineComponent } from 'vue'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'

  export default defineComponent({
  components: {
    AnnouncementOrResourceCard,
    Tag,
    SimpleMap,
    RichBodyContent,
    RichTextArea,
  },
  props: {
    announcement: {
      type: Object,
    },
    availableActions: {
      type: Array,
      default: () => [],
    },
    availableActionsForCommunityEcosystem: {
      type: Array,
      default: () => [],
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    enableComment: {
      type: Boolean,
      default: true,
    },
    showTags: {
      type: Boolean,
      default: true,
    },
    showImage: {
      type: Boolean,
      default: true,
    },
    showReadMore: {
      type: Boolean,
      default: true,
    },
    fullCardClickable: {
      type: Boolean,
      default: false,
    },
    comments: {
      type: Array,
      default: null,
    },
    isTagActive: {
      type: Function,
      default: () => false,
    },
    isCommunityActive: {
      type: Function,
      default: () => false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      errors: {},
      showMoreTags: false,
      tagsAreOverflowing: false,
      fixedImageSize: '600px',
      componentKey: 0,
      actionInProgress: '',
      hasScrolledToComments: false,
      newComment: '',
      loadingNewComment: false,
      commentsIconKey: 0,
      showMoreAvailableActions: false,
      newCommentSuccess: false,
      ANNOUNCEMENT_ACTION_TYPES,
      ANNOUNCEMENT_SHARE_ACTION_TYPES: [ANNOUNCEMENT_ACTION_TYPES.SHARE_FACEBOOK, ANNOUNCEMENT_ACTION_TYPES.SHARE_TWITTER, ANNOUNCEMENT_ACTION_TYPES.SHARE_LINKEDIN],
      announcementCopy: {},
    }
  },
  emits: ['delete-comment', 'add-comment', 'clickActionButton'],
  methods: {
    getFormattedEventDate,
    showExternalCalendarModal (announcement) {
      trackHeapEvent('announcementDetail.clickAddToCalendar', { 'added': announcement.user_event_calendar })

      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        announcement: announcement,
      })

      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ANNOUNCEMENT_CALENDAR)
    },
    getNetworkForActionType (actionType) {
      switch (actionType) {
        case this.ANNOUNCEMENT_ACTION_TYPES.SHARE_FACEBOOK:
          return 'facebook'
        case this.ANNOUNCEMENT_ACTION_TYPES.SHARE_TWITTER:
          return 'twitter'
        case this.ANNOUNCEMENT_ACTION_TYPES.SHARE_LINKEDIN:
          return 'linkedin'
      }
    },
    displayAnnouncementTags (withEventsTag) {
      if (typeof withEventsTag === 'undefined') {
        withEventsTag = true
      }

      let tags = []

      if (this.announcement.tags && this.announcement.tags.length > 0) {
        tags = this.announcement.tags
            .map(tag => ({
              ...tag,
              isActive: this.isTagActive(tag),
            }))
            .sort((a, b) => b.isActive - a.isActive)
            .slice()
      }

      if (withEventsTag && this.announcement.is_event && this.announcement.event_label) {
        tags.push({
          label: this.announcement.event_label,
          text: this.announcement.event_label,
          value: null,
          isActive: false,
        })
      }

      return tags
    },
    canStartConversation (user) {
      if (!this.isLoggedIn) {
        return false
      }

      if (!this.hasAccessToConversations) {
        return false
      }

      if (user.is_deleted) {
        return false
      }

      if (user.id === this.userId) {
        return false
      }

      return this.$store.getters.canActorsBeContacted || this.isOwner
    },
    startConversation (user, trackHeap = 'announcementDetail.clickConversation') {
      if (!this.canStartConversation(user) || this.userId === user.id) {
        return
      }

      trackHeapEvent(trackHeap)

      Conversations.post({ recipient_id: user.id }).then((conversation) => {
        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
        this.$router.push('/profile/conversations/' + conversation.id).then(() => {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
            component: 'user-information-side-panel',
            metaData: {
              userId: user.id,
              allowDelete: false,
            },
          })
        })
      })
    },
    addComment () {
      if (!this.isLoggedIn) {
        return this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      }

      this.validateForm()

      if (!_isEmpty(this.errors) || !this.newComment) {
        return
      }

      this.loadingNewComment = true
      this.commentsIconKey++
      this.$emit('add-comment', this.newComment)
      this.newCommentSuccess = true
    },
    editable (comment) {
      if (!this.$store.state.user || !this.$store.state.user.profile) {
        return false
      }

      return this.isOwner || comment.user_id === this.$store.state.user.profile.id
    },
    deleteComment (comment) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        name: 'comment',
        resource: comment,
        modalContextType: 'comment',
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    confirmDeleteComment (comment) {
      deleteComment(this.announcement.id, comment.id)
          .then(() => {
            this.$emit('delete-comment', comment)
          })
    },
    validateForm () {
      this.errors = {}

      if (!this.newComment || this.commentBodyWithoutHtml.length === 0) {
        this.errors = { comment: [this.$t('announcement_comment_error_required')] }
      }
    },
    onClickTag (event, tag) {
      if (tag.value) {
        this.$emit('clickTag', tag)
      }
    },
    onClickNewComment () {
      if (!this.isLoggedIn) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      } else {
        this.$emit('clickActionButton', ANNOUNCEMENT_ACTION_TYPES.COMMENT)
      }
    },
    onClickReadMore () {
      this.$emit('clickActionButton', ANNOUNCEMENT_ACTION_TYPES.DETAIL)
    },
    toggleAction (action) {
      if (action.type === ANNOUNCEMENT_ACTION_TYPES.LIKE) {
        if (!this.isLoggedIn) {
          return this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
        }

        likeNotification(this.announcement.id, !this.announcementCopy.user_like)
            .then(response => {
              fetchNotification(this.announcement.id)
                  .then((response) => {
                    this.announcementCopy = response
                  })
            })
            .catch(e => {
              if (e && e.error) {
                this.error = e.error
              }
            })

        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ANNOUNCEMENT_LIKE, MATOMO_EVENT_ACTIONS.LIKE, this.announcement.id)

        return
      }

      this.$emit('clickActionButton', action.type)

      if (!this.isLoggedIn) {
        return
      }

      // Prevent multiple actions
      if (this.actionInProgress === action.type) {
        return
      }

      if (action.type !== ANNOUNCEMENT_ACTION_TYPES.EDIT) {
        this.actionInProgress = action.type
        this.componentKey++
      }

      // Don't toggle icon when clicking the comments icon
      if (action.type === ANNOUNCEMENT_ACTION_TYPES.COMMENT && this.isDetail) {
        this.actionInProgress = ''
      }
    },
    verifyTagVisibility () {
      setTimeout(() => {
        if (this.announcement && this.announcement.tags && this.$refs.tagsContainer) {
          const tagsContainer = this.$refs.tagsContainer
          this.tagsAreOverflowing = tagsContainer.offsetHeight > 40
          return
        }
        this.showMoreTags = false
        this.tagsAreOverflowing = false
      })
    },
    getNameForIcon (icon) {
      switch (icon.toUpperCase()) {
        case ANNOUNCEMENT_ACTION_TYPES.PIN:
          return this.announcement.user_pin ? 'pin' : 'pin-outline'
        case ANNOUNCEMENT_ACTION_TYPES.LIKE:
          return this.announcementCopy.user_like ? '' : 'pin-outline'
        case ANNOUNCEMENT_ACTION_TYPES.EVENT_CALENDAR:
          return this.announcement.user_event_calendar ? 'calendar' : 'calendar-plus'
        default:
          return icon
      }
    },
    getCountPositionForAction (action) {
      switch (action.type) {
        case ANNOUNCEMENT_ACTION_TYPES.COMMENT:
          return 'announcement-card__action-top-right'
        case ANNOUNCEMENT_ACTION_TYPES.LIKE:
          return 'announcement-card__action-top-left'
        default:
          return ''
      }
    },
    getCountForAction (action) {
      switch (action.type) {
        case ANNOUNCEMENT_ACTION_TYPES.COMMENT:
          return this.announcement.comments
        case ANNOUNCEMENT_ACTION_TYPES.LIKE:
          return this.announcementCopy.likers ? `${this.announcementCopy.likers.length}` : '0'
        default:
          return ''
      }
    },
    // @deprecated
    getClassesForAction (action) {
      /*switch (action.type) {
        case ANNOUNCEMENT_ACTION_TYPES.FEATURE:
          return this.announcement.featured ? `fas ${action.faIcon}` : `far ${action.faIcon}`
        case ANNOUNCEMENT_ACTION_TYPES.LIKE:
          return this.announcementCopy.user_like ? `fas ${action.faIcon}` : `far ${action.faIcon}`
        default:
          return `far ${action.faIcon}`
      }*/
      return ''
    },
    onCommentsChange () {
      if (this.comments && this.commentsAreLoaded) {
        this.loadingNewComment = false
        this.commentsIconKey++
        this.newComment = ''
        if (this.shouldScrollToComments && !this.hasScrolledToComments) {
          setTimeout(() => {
            this.$refs.comments.scrollIntoView({ behavior: 'smooth' })
            this.hasScrolledToComments = true
          })
        }
      }
    },
    getLocation () {
      let context = this.announcement.context

      if (typeof context === 'string') {
        context = JSON.parse(this.announcement.context)
      }

      if (context.location.lat === null) {
        return false
      }

      return context.location
    },
    showAvailableAction (actionType) {
      if (this.showMoreAvailableActions) {
        return true
      }

      switch (actionType) {
        case ANNOUNCEMENT_ACTION_TYPES.DELETE:
        case ANNOUNCEMENT_ACTION_TYPES.EDIT:
        case ANNOUNCEMENT_ACTION_TYPES.SHARE_FACEBOOK:
        case ANNOUNCEMENT_ACTION_TYPES.SHARE_TWITTER:
        case ANNOUNCEMENT_ACTION_TYPES.SHARE_LINKEDIN:
          return false
        default:
          return true
      }
    },
    canViewMoreActions (announcement) {
      if (this.$store.getters.isMember || ((this.$store.getters.userId && this.$store.getters.userId == announcement.created_by) || (this.$store.getters.isOwner))) {
        return true
      }
    },
    displayAllAvailableActions () {
      this.showMoreAvailableActions = true
    },
    showReadMoreButton (announcement) {
      if (!this.showReadMore) {
        return false
      }

      if (!_isEmpty(announcement.action_url) || !_isEmpty(announcement.file) || this.announcementBodyWithoutHtml(announcement).length > 140) {
        return true
      }
    },
    announcementBodyWithoutHtml (announcement) {
      return (announcement.body && announcement.body.replace(/<\/?[^>]+(>|$)/g, '')) || ''
    },
  },
  computed: {
    activeCommunityId () {
      if (!this.$route || !this.$route.params || !this.$route.params.communityId) {
        return null
      }

      return this.$route.params.communityId
    },
    isCommunityEcosystem () {
      return this.$store.getters.isCommunityEcosystem
    },
    isMember () {
      return this.$store.getters.isMember
    },
    postedFromActor () {
      return this.announcement.actor_id
    },
    backgroundImageStyle () {
      if (this.postedFromActor) {
        const actor = {
          logo: this.announcement.actor_logo,
          url: this.announcement.actor_url,
        }

        if (actorLogo(actor)) {
          return `background-image: url(${actorLogo(actor)}`
        } else {
          return ''
        }
      }

      if (this.announcement.claimedActor) {
        return `background-image: url(${this.announcement.user.photo_url}`
      } else if (this.announcement.actor_id) {
        const actor = {
          logo: this.announcement.actor_logo,
          url: this.announcement.actor_url,
        }

        return `background-image: url(${actorLogo(actor)}`
      } else if (!this.announcement.actor_id) {
        return `background-image: url(${this.announcement.user.photo_url}`
      } else {
        return ''
      }
    },
    announcementShortUrl () {
      if (this.announcement.short_url && this.announcement.short_url.length > 0) {
        return this.announcement.short_url
      }

      return window.location.href
    },
    announcementFullUrl () {
      return window.location.href
    },
    isOwner () {
      return this.$store.getters.isOwner
    },
    isTeamMember () {
      return this.$store.getters.isTeamMember
    },
    eventTags () {
      return this.displayAnnouncementTags(true, false)
          .filter(tag => tag.label === 'Event')
    },
    userId () {
      return this.$store.getters.userId
    },
    displayAnnouncementCommunityTags () {
      let tags = []

      if (this.announcement.communities && this.announcement.communities.length > 0) {
        tags = this.announcement.communities
            .map(community => ({
              label: community.label,
              text: community.text,
              value: null,
              isActive: this.isCommunityActive(community),
            }))
            .sort((a, b) => b.isActive - a.isActive)
            .slice()
      }

      return tags
    },
    commentBodyWithoutHtml () {
      return (this.newComment && this.newComment.replace(/<\/?[^>]+(>|$)/g, '')) || ''
    },
    commentsAreLoaded () {
      return Array.isArray(this.comments)
    },
    shouldScrollToComments () {
      return this.$route.hash === '#comments'
    },
    createdDateString () {
      if (!this.announcement.created_at) {
        return
      }

      const result = new Date(this.announcement.created_at)
      if (!isNaN(result.getTime())) {
        return moment(result.toLocaleString('en-US', { timeZone: 'Europe/Brussels' })).format('DD/MM/YYYY')
      }
    },
    announcementTags () {
      if (!this.announcement.tags) {
        return ''
      }

      return this.announcement.tags.map(tag => tag.label).join(',')
    },
  },
  mounted () {
    this.verifyTagVisibility()
    this.onCommentsChange()
    this.$bus.on('commentDeleteConfirmation', (context) => {
      this.confirmDeleteComment(context.resource)
    })
  },
  created () {
    this.announcementCopy = this.announcement
  },
  beforeUnmount () {
    this.$bus.off('commentDeleteConfirmation')
  },
  watch: {
    announcement () {
      // fa-icons through <i class=".."> don't seem to update automatically, while <icon>'s do for some reason. Updating the key seems to be a solution for this problem
      this.componentKey++
      this.actionInProgress = ''

      this.announcementCopy = this.announcement
    },
    comments () {
      this.onCommentsChange()
    },
  },
  mixins: [ConversationsMixin, TranslationsMixin, AnnouncementMixin],
})
</script>
<style
    lang="scss"
    scoped
>
@import "../../../scss/variables.scss";

.clickable-address {
  display: inline-block;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: var(--primary-community);
  }
}

:deep(.button--minimal:hover) {
  background-color: white;
}

.comment-button {
  :deep(.button--minimal:hover) {
    background-color: white;
  }

  :deep(.button) {
    display: flex;
    margin-left: 15px;
    margin-top: 14px;
  }
}

// Custom style for static homepage
.static-home-community-ecosystem {
  .full-card-clickable {
    &:hover {
      cursor: pointer;
    }

    .announcement-header:hover {
      cursor: default;
    }
  }

  .announcement-card {
    transform: none;
    transition: all 0.3s ease-in-out;

    .announcement-card__content {
      border-radius: 12px;
    }

    .announcement-header {
      border-radius: 12px;
      background-color: #EBEBEC;
    }

    .announcement-card__content {
      background-color: #EBEBEC;

      &.enable-click-read-more:hover::before {
        box-shadow: none;
      }
    }

    .announcement-content-container {
      border-radius: 12px;
    }

    &:hover {
      .announcement-header {
        -webkit-transition: all 0.3s ease-in-out;
        background-color: #EBFEFA; //var(--primary-extra-lightest);
      }

      .announcement-card__content {
        -webkit-transition: all 0.3s ease-in-out;
        background-color: #EBFEFA; //var(--primary-extra-lightest);
      }
    }
  }
}

.announcement-header-actions {
  display: flex;
  flex-direction: row;
  gap: 18px;
  padding-right: 18px;
  margin-bottom: 10px;
  margin-top: 7px;

  .announcement-header-actions--item {
    .announcement-card__action {
      display: flex;
      flex-direction: row;
      gap: 3px;
      color: #323441;
      font-size: 11px;
      font-weight: bold;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
