import { MONTHS } from './helpers.js'
import moment from 'moment-timezone'

export const FULL_MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export function transformDateToLocalTimezone (d, format = 'UTC', isDateUTCBased = false) {
  // If no date is passed, use the current date
  d = d || new Date()

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (typeof d === 'string') {
    if (isDateUTCBased) {
      d = moment.utc(d).tz(d, timezone)
    } else {
      d = moment.tz(d, timezone)
    }
  }

  // Check if the moment object is valid
  if (!moment(d).isValid()) {
    return '' // Invalid date
  }

  // If format is 'UTC', convert the date to the specified timezone
  if (format === 'UTC') {
    return moment(d).tz(timezone).format('YYYY-MM-DDTHH:mm:ss')
  }

  // Return the formatted date according to the specified timezone
  return moment(d).tz(timezone).format(format)
}

export function transformDateToUTC (inputDateTime, timezone = null) {
  // If no timezone is passed, derive it from the browser's local timezone
  if (!timezone) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  // Parse the input assuming it's in the user's local timezone
  const localTime = moment.tz(inputDateTime, timezone)

  return localTime.utc()
}

// For this.$t to work, this function should be called from "this" vue context
export function toDateStringTranslated (date) {
  const d = new Date(date)
  const year = d.getFullYear()

  // Mar 14
  if (year === new Date().getFullYear()) {
    return this.$t('countdown_months').split(',')[d.getMonth()] + ' ' + d.getDate()
  }

  // 14 Mar 2017
  return d.getDate() + ' ' + this.$t('countdown_months').split(',')[d.getMonth()] + ' ' + year
}

export function toDateString (date) {
  const d = new Date(date)
  const year = d.getFullYear()

  // Mar 14
  if (year === new Date().getFullYear()) {
    return MONTHS[d.getMonth()] + ' ' + d.getDate()
  }

  // 14 Mar 2017
  return d.getDate() + ' ' + MONTHS[d.getMonth()] + ' ' + year
}

export function toDateStringSlashed (date) {
  const d = new Date(date)
  const year = d.getFullYear()

  // Mar 14
  if (year === new Date().getFullYear()) {
    return MONTHS[d.getMonth()] + ' / ' + d.getDate()
  }

  // 14 04 2017
  return d.getDate() + '/' + d.getMonth() + '/' + year
}

export function toDateStringSlashedUs (date) {
  const d = new Date(date)
  const year = d.getFullYear()

  // YYYY/MM/DD
  // return d.getDate() + '/' + d.getMonth() + '/' + year
  return year + '/' + (d.getMonth() + 1) + '/' + d.getDate()
  // return d.getDate() + ' / ' + (d.getMonth() + 1) + ' / ' + year
}

export function toDateStringDashed (date) {
  const d = new Date(date)
  const year = d.getFullYear()

  // YYYY-mm-dd
  // return year + '-' + (d.getMonth() + 1) + '-' + d.getDate();
  return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2)
}

// 14 Mar 2017 03:14
export function toDatetimeString (date, isDateUTCBased = false) {
  const d = localTimestamp(date, isDateUTCBased)
  return parseInt(d.slice(8, 10)) + ' ' + MONTHS[parseInt(d.slice(5, 7)) - 1] + ' ' + d.slice(0, 4) + ', ' + d.slice(11, 16)
}

export function toDayFullMonth (date) {
  if (typeof date === 'string') {
    date = Date.parse(date.replace(' ', 'T'))
  }

  const d = new Date(date)
  const year = d.getFullYear()

  // March 14
  return FULL_MONTHS[d.getMonth()] + ' ' + d.getDate()
}

export function toFullMonth (date) {
  if (typeof date === 'string') {
    date = Date.parse(date.replace(' ', 'T'))
  }

  const d = new Date(date)
  const year = d.getFullYear()

  // March
  if (year === new Date().getFullYear()) {
    return FULL_MONTHS[d.getMonth()]
  }

  // March 2017
  return FULL_MONTHS[d.getMonth()] + ' ' + year
}

export function toMonthString (date) {
  const d = new Date(date)
  const year = d.getFullYear()

  // Mar
  if (year === new Date().getFullYear()) {
    return MONTHS[d.getMonth()]
  }

  // Mar 2017
  return MONTHS[d.getMonth()] + ' ' + year
}

export function toOnlyMonthString (date) {
  const d = new Date(date)

  // Mar
  return MONTHS[d.getMonth()]
}

export function toOnlyDayMonthString (date) {
  const d = new Date(date)

  // 14 Mar
  return d.getDate() + ' ' + MONTHS[d.getMonth()]
}

// Times in milliseconds
const second = 1e3
const minute = 6e4
const hour = 36e5
const day = 864e5

export function timeAgo (timestamp) {
  return timeAgoWithMonthList(timestamp, MONTHS, false)
}

export function daysAgo (timestamp) {
  if (timestamp instanceof Date) {
    timestamp = timestamp.getTime()
  }

  if (typeof timestamp === 'string') {
    timestamp = new Date(Date.parse(timestamp.replace(' ', 'T'))).getTime()
  }

  const diff = Math.abs(timestamp - Date.now())

  const currentServerTime = moment(new Date().toLocaleString('en-US', { timeZone: 'Europe/Brussels' }))
  const targetDate = moment(timestamp)
  const duration = moment.duration(currentServerTime.diff(targetDate))

  if (diff <= second) {
    return '1s'
  } else if (diff < minute) {
    return Math.floor(diff / 1000) + 's'
  } else if (diff < hour) {
    return Math.floor(diff / 1000 / 60) + 'm'
  } else if (diff < day) {
    return Math.floor(diff / 1000 / 3600) + 'h'
  } else {
    return duration.days() + ' day' + (duration.days() > 1 ? 's' : '')
  }
}

// For this.$t to work, this function should be called from "this" vue context
export function timeAgoTranslated (timestamp) {
  return timeAgoWithMonthList(timestamp, this.$t('countdown_months').split(','), true)
}

function timeAgoWithMonthList (timestamp, monthList, reverseDates) {
  if (timestamp instanceof Date) {
    timestamp = timestamp.getTime()
  }

  if (typeof timestamp === 'string') {
    timestamp = new Date(Date.parse(timestamp.replace(' ', 'T'))).getTime()
  }

  const diff = Math.abs(timestamp - Date.now())

  if (diff <= second) {
    return '1s'
  } else if (diff < minute) {
    return Math.floor(diff / 1000) + 's'
  } else if (diff < hour) {
    return Math.floor(diff / 1000 / 60) + 'm'
  } else if (diff < day) {
    return Math.floor(diff / 1000 / 3600) + 'h'
  } else {
    const d = new Date(timestamp)
    const year = d.getFullYear()

    // Mar 14
    if (year === new Date().getFullYear()) {
      if (reverseDates) {
        return d.getDate() + ' ' + monthList[d.getMonth()]
      }
      return monthList[d.getMonth()] + ' ' + d.getDate()
    }

    // 14 Mar 2017
    if (reverseDates) {
      return d.getDate() + ' ' + monthList[d.getMonth()] + ' ' + year
    } else {
      return monthList[d.getMonth()] + ' ' + d.getDate() + ' ' + year
    }
  }
}

/*
export function timestamp (timestamp) {
  if (timestamp instanceof Date) {
    timestamp = timestamp.getTime()
  }

  if (typeof timestamp === 'string') {
    timestamp = new Date(Date.parse(timestamp.replace(' ', 'T'))).getTime()
  }

  if (timestamp) {
    return new Date(timestamp).toJSON().slice(0, 19).replace('T', ' ')
  }

  return timestamp
} */

export function localTimestamp (timestamp, isDateUTCBased = false) {
  timestamp = new Date(typeof timestamp === 'string' ? Date.parse(timestamp.replace(' ', 'T')) : timestamp)
  return transformDateToLocalTimezone(timestamp, isDateUTCBased)
}

// seconds since epoch => year
export function fromEpochTimeToYear (n) {
  var d = new Date(1000 * n)
  return !n ? 0 : d.getFullYear()
}

// For this.$t to work, this function should be called from "this" vue context
export function getCallTimeDifference (date) {
  const targetDate = moment(date)
  // get the current server time to compare to
  const currentServerTime = moment(new Date().toLocaleString('en-US', { timeZone: 'Europe/Brussels' }))
  const hasExpired = targetDate.valueOf() < currentServerTime.valueOf()
  const duration = hasExpired ? moment.duration(currentServerTime.diff(targetDate)) : moment.duration(targetDate.diff(currentServerTime))
  const yearsRemain = duration.years()
  const monthsRemain = duration.months()
  const daysRemain = duration.days()
  const hoursRemain = duration.hours()
  const minutesRemain = duration.minutes()
  const secondsRemain = duration.seconds()

  let result = ''
  if (hasExpired) {
    if (daysRemain) {
      // only show until 24 hours after due date
      return
    }
    result = `${this.$t('countdown_expired')} `
  }

  const countdownYear = this.$t('countdown_year')
  const countdownMonth = this.$t('countdown_month')
  const countdownDay = this.$t('countdown_day')
  const countdownHour = this.$t('countdown_hour')
  const countdownMinute = this.$t('countdown_minute')
  const countdownSecond = this.$t('countdown_second')

  result += (yearsRemain ? `<span>${yearsRemain}</span>${countdownYear} ` : '') +
    (monthsRemain ? `<span>${monthsRemain}</span>${countdownMonth} ` : '') +
    (daysRemain ? `<span>${daysRemain}</span>${countdownDay} ` : '') +
    (hoursRemain ? `<span>${hoursRemain}</span>${countdownHour} ` : '') +
    (minutesRemain ? `<span>${minutesRemain}</span>${countdownMinute} ` : '') +
    (secondsRemain ? `<span>${secondsRemain}</span>${countdownSecond} ` : '')

  if (hasExpired) {
    return result + ` ${this.$t('countdown_ago')}`
  }

  return result + ` ${this.$t('countdown_remaining')}`
}

// For this.$t to work, this function should be called from "this" vue context
export function getChallengeTimeDifference (date, addSuffix) {
  if (!date || date.startsWith('-')) {
    return
  }

  const targetDate = moment(date)
  // get the current server time to compare to
  const currentServerTime = moment(new Date().toLocaleString('en-US', { timeZone: 'Europe/Brussels' }))
  const hasExpired = targetDate.valueOf() < currentServerTime.valueOf()
  const duration = hasExpired ? moment.duration(currentServerTime.diff(targetDate)) : moment.duration(targetDate.diff(currentServerTime))
  const yearsRemain = duration.years()
  const monthsRemain = duration.months()
  const daysRemain = duration.days()
  const hoursRemain = duration.hours()
  const minutesRemain = duration.minutes()
  const secondsRemain = duration.seconds()

  let result = ''
  if (hasExpired) {
    if (daysRemain && daysRemain < 7) {
      // only show until 24 hours after due date
      return `<span>${this.$t('countdown_expired')}</span>`
    }
    return
  }

  const countdownYear = this.$t('countdown_year')
  const countdownMonth = this.$t('countdown_month')
  const countdownDay = this.$t('countdown_day')
  const countdownHour = this.$t('countdown_hour')
  const countdownMinute = this.$t('countdown_minute')
  const countdownSecond = this.$t('countdown_second')

  result += (yearsRemain ? `<span>${yearsRemain}</span>${countdownYear} ` : '') +
    (monthsRemain ? `<span>${monthsRemain}</span>${countdownMonth} ` : '') +
    (daysRemain ? `<span>${daysRemain}</span>${countdownDay} ` : '') +
    (hoursRemain ? `<span>${hoursRemain}</span>${countdownHour} ` : '')
  if (!daysRemain) {
    result += (minutesRemain ? `<span>${minutesRemain}</span>${countdownMinute} ` : '')
    result += (secondsRemain ? `<span>${secondsRemain}</span>${countdownSecond} ` : '')
  }

  if (addSuffix) {
    result += ` <span style="font-weight: 300;">${this.$t('countdown_remaining')}</span>`
  }

  return result
}

// For this.$t to work, this function should be called from "this" vue context
export function getEventTimeDifference (date, endDate, addSuffix) {
  // NOTE: the start time and end time is not yet being taken into account
  if (!date || date.startsWith('-')) {
    return
  }

  // When no end date specified, set it the same as the start date
  if (!endDate || endDate.startsWith('-')) {
    endDate = date
  }

  const targetDate = moment(date)

  // get the current server time to compare to
  const currentServerTime = moment(new Date().toLocaleString('en-US', { timeZone: 'Europe/Brussels' }))

  // Calculate time until start date
  let hadEndExpired = false
  let hasExpired = targetDate.valueOf() < currentServerTime.valueOf()
  let duration = hasExpired ? moment.duration(currentServerTime.diff(targetDate)) : moment.duration(targetDate.diff(currentServerTime))
  let daysRemain = duration.days()

  // Calculate time until end date
  if (hasExpired && daysRemain > 0 && date !== endDate) {
    hadEndExpired = true
    const targetEndDate = moment(endDate)

    hasExpired = targetEndDate.valueOf() < currentServerTime.valueOf()
    duration = hasExpired ? moment.duration(currentServerTime.diff(targetEndDate)) : moment.duration(targetEndDate.diff(currentServerTime))

    daysRemain = duration.days()
  }

  const yearsRemain = duration.years()
  const monthsRemain = duration.months()

  const countdownYear = this.$t('countdown_full_year').toLowerCase()
  const countdownMonth = this.$t('countdown_full_month').toLowerCase()
  const countdownDay = this.$t('countdown_full_day').toLowerCase()
  const countdownYears = this.$t('countdown_full_years').toLowerCase()
  const countdownMonths = this.$t('countdown_full_months').toLowerCase()
  const countdownDays = this.$t('countdown_full_days').toLowerCase()

  const result = (yearsRemain ? `<span>&nbsp;${yearsRemain}&nbsp;</span>` + (yearsRemain > 1 ? `${countdownYears}` : `${countdownYear}`) : '') +
    (monthsRemain ? `<span>&nbsp;${monthsRemain}&nbsp;</span>` + (monthsRemain > 1 ? `${countdownMonths}` : `${countdownMonth}`) : '') +
    (daysRemain ? `<span>&nbsp;${daysRemain}&nbsp;</span>` + (daysRemain > 1 ? `${countdownDays}` : `${countdownDay}`) : '')

  if (hasExpired) {
    if (daysRemain === 0) {
      return `<span>${this.$t('announcement_today')}</span>`
    }

    // only show "expired ago"  until 24 hours after due date
    if (daysRemain < 7 && monthsRemain < 1 && yearsRemain < 1) {
      return `<span>${this.$t('announcement_expired_ago', { time: result })}</span>`
    }

    return `<span>${this.$t('announcement_expired')}</span>`
  }

  if (!daysRemain) {
    if (daysRemain === 0) {
      return `<span>${this.$t('announcement_today')}</span>`
    }

    return `<span>${this.$t('announcement_tomorrow')}</span>`
  }

  if (addSuffix) {
    if (hadEndExpired) {
      return `<span>${this.$t('announcement_until_event_ends', { time: result })}</span>`
    }

    return `<span>${this.$t('announcement_until_event', { time: result })}</span>`
  }

  return result
}

export function formatEventDate (date, format = 'ddd, Do MMM YYYY, hh:mmA', isDateUTCBased = false) {
  return transformDateToLocalTimezone(date, format, isDateUTCBased)
}

export function getFormattedEventDate (announcementOrEvent, oneLine) {
  // Note: we added specific hour/minute start/end time afterwards, so here we make sure all 'old' posts won't show '12AM'

  // Display the start date + time without "until"
  if (!announcementOrEvent.end_date || announcementOrEvent.start_date == announcementOrEvent.end_date || announcementOrEvent.end_date.startsWith('0000-00-00')) {
    return formatEventDate(announcementOrEvent.start_date, 'ddd, Do MMM YYYY, hh:mmA', true)
  }

  // Display the end time after "until"
  if (formatEventDate(announcementOrEvent.start_date, 'Do MMM YYYY') == formatEventDate(announcementOrEvent.end_date, 'Do MMM YYYY')) {
    return formatEventDate(announcementOrEvent.start_date, 'ddd, Do MMM YYYY', true) + ',' + (oneLine ? ' ' : '<br />') + formatEventDate(announcementOrEvent.start_date, 'hh:mmA', true) + ' - ' + formatEventDate(announcementOrEvent.end_date, 'hh:mmA', true)
  }

  // Display the end date + time after "until"
  return formatEventDate(announcementOrEvent.start_date, 'ddd, Do MMM YYYY, hh:mmA', true) + ' -' + (oneLine ? ' ' : '<br />') + formatEventDate(announcementOrEvent.end_date, 'ddd, Do MMM YYYY, hh:mmA', true)
}
